const config = {
  env: process.env.NODE_ENV,
  publicUrl: process.env.PUBLIC_URL,
  apiUrl: process.env.REACT_APP_API_URL,
  oauthClientId: process.env.REACT_APP_OAUTH_CLIENT_ID,
  oauthClientSecret: process.env.REACT_APP_OAUTH_CLIENT_SECRET,
  oauthAudience: process.env.REACT_APP_OAUTH_AUDIENCE,
};

export default config;
