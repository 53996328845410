import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getBusinessLogics, deleteBusinessLogic } from 'api/business_logics';
import DataTable from 'components/DataTable';
import { useNavigate } from 'react-router-dom';

const BusinessLogicListViewSection = () => {
  const businessLogicTableColumns = useMemo(() => [
    {
      field: 'business_logic_id',
      label: 'Business ID',
    },
    { field: 'business_logic_name' },
  ], []);

  const [businessLogics, setBusinessLogics] = useState([]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();

  const fetchBusinessLogicsFromApi = useCallback(() => {
    return getBusinessLogics()
      .then(({ data }) => {
        setBusinessLogics(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const onPressAdd = useCallback(() => {
    navigate('/create_business_logic');
  }, [navigate]);

  const onPressEdit = useCallback((businessLogicId) => {
    navigate(`/business_logic?business_logic_id=${businessLogicId}&edit=true`);
  }, [navigate]);

  const onPressDelete = useCallback((businessLogicId) => {
    return deleteBusinessLogic(businessLogicId)
      .then(() => {
        return fetchBusinessLogicsFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchBusinessLogicsFromApi, setAuth]);

  useEffect(() => {
    fetchBusinessLogicsFromApi();
  }, [fetchBusinessLogicsFromApi]);

  return (
    <MKBox my={2}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h5">Business Logics</MKTypography>
      </MKBox>
      <DataTable
        data={businessLogics}
        columns={businessLogicTableColumns}
        idField="business_logic_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        selectable
      />
    </MKBox>
  );
};

export default BusinessLogicListViewSection;
