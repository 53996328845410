import SimpleHeaderSection from 'sections/SimpleHeader';
import MenuHeaderSection from 'sections/MenuHeader';
import SimpleFooterSection from 'sections/SimpleFooter';
import ContactFooterSection from 'sections/ContactFooter';
import LoginSection from 'sections/Login';
import TextSection from 'sections/Text';
import DateSection from 'sections/Date';
import ImageSection from 'sections/Image';
import ButtonSection from 'sections/Button';
import IconSection from 'sections/Icon';
import FlexBoxSection from 'sections/FlexBox';
import GridsSection from 'sections/Grids';
import BreadcrumbsSection from 'sections/Breadcrumbs';
import FileUploaderSection from 'sections/FileUploader';
import UsersTableSection from 'sections/UsersTable';
import SolutionsTableSection from 'sections/SolutionsTable';
import DynamicTableListViewSection from 'sections/DynamicTableListView';
import DynamicTableDetailViewSection from 'sections/DynamicTableDetailView';
import DynamicTableAttributeViewSection from 'sections/DynamicTableAttributeView';
import EditCollectionFormSection from 'sections/EditCollectionForm';
import CollectionBreadcrumbsSection from 'sections/CollectionBreadcrumbs';
import EditSolutionSection from 'sections/EditSolution';
import AppListViewSection from 'sections/AppListView';
import AppCreateSection from 'sections/AppCreate';
import EditUserSection from 'sections/EditUser';
import EditInputsFormSection from 'sections/EditInputsForm';
import TwoButtonCallApiSection from 'sections/TwoButtonCallApi';
import BusinessLogicListViewSection from 'sections/BusinessLogicListView';
import BusinessLogicCreateSection from 'sections/BusinessLogicCreate';
import BusinessLogicEditSection from 'sections/BusinessLogicEdit';
import EventTriggerEditSection from 'sections/EventTriggerEdit';
import EventTriggerCreateSection from 'sections/EventTriggerCreate';
import HandlerCreateSection from 'sections/HandlerCreate';
import HandlerEditSection from 'sections/HandlerEdit';
import SignalCreateSection from 'sections/SignalCreate';
import SignalEditSection from 'sections/SignalEdit';
import HandlerParameterCreateSection from 'sections/HandlerParameterCreate';
import HandlerParameterEditSection from 'sections/HandlerParameterEdit';
import BreadcrumbHeaderSection from 'sections/BreadcrumbHeader';
import BannerSection from 'sections/Banner';
import MapSection from 'sections/Map';
import HeaderNavbarSection from 'sections/HeaderNavbar';
import AccordionSection from 'sections/Accordion';
import MetadataSection from 'sections/Metadata';
import TextFromApiSection from 'sections/TextFromApi';
import CollapseBoxSection from 'sections/CollapseBox';
import ForgetPasswordSection from 'sections/ForgetPassword';
import ResetPasswordSection from 'sections/ResetPassword';
import ContactFormSection from 'sections/ContactForm';
import LosFormSection from 'sections/LosForm';
import CarouselSection from 'sections/Carousel';
import DealsTableSection from 'sections/DealsTable';
import DealCreateSection from './DealCreate';
import DealDetailSection from './DealDetail';

export const sectionDefinitionComponentMapping = {
  '25310d81-d558-40a8-bac5-18be605cae8f': SimpleHeaderSection,
  '092b2173-d14f-4f94-998c-0470cc0d6577': MenuHeaderSection,
  'a32667b8-22a4-4efe-9986-16877aa9076a': SimpleFooterSection,
  '5234c831-83fc-4e57-879d-b45ea6e18120': ContactFooterSection,
  '7b7002a2-bcc1-4ba5-a12f-8e6b9af7cc78': LoginSection,
  '656bb778-f965-457d-8f79-9e5aaaeda369': TextSection,
  '49daf9ec-f5d2-4cbf-af3f-d88291c1d907': DateSection,
  '0187cbe3-eaba-44a4-bd2d-0393c20f99b3': ImageSection,
  'dc5ed9fd-fcff-4320-81bc-f1b4ee55e637': ButtonSection,
  '42de2900-6bfb-442f-ac99-ce8e373b131d': IconSection,
  '2f9195b1-6c86-448a-8bf2-74196c8e8788': FlexBoxSection,
  'a56b67a1-f366-4891-b89e-8b275e33e7a4': GridsSection,
  '23ed1cc0-2376-4d52-8719-5e0344ebd3ae': BreadcrumbsSection,
  '11317177-6f1f-4859-976a-0347c6750740': FileUploaderSection,
  '7065ea00-46eb-4426-bb55-a5ed9bc5dc32': UsersTableSection,
  'b8344014-a62d-47ac-abd1-5b81427e95c6': SolutionsTableSection,
  'a328c693-fdb4-47a5-80f2-5133cdd55517': DynamicTableListViewSection,
  '08f9d734-9822-4ee8-a65d-7c73ab93d164': DynamicTableDetailViewSection,
  '5f517188-8cc9-4a46-810a-344ab73d7b87': DynamicTableAttributeViewSection,
  'fee5da64-84a8-4d8e-b743-09398979bed2': EditCollectionFormSection,
  '72f7d214-9c7a-4b69-b7ce-7562fb1c9b6a': CollectionBreadcrumbsSection,
  '5fb3219c-e8c0-4c72-8981-13bc53bdb66e': EditSolutionSection,
  '0e19f2f7-e35e-4dc3-bdac-ae1efe210bcc': AppListViewSection,
  '7a9ab307-7e4e-4cc0-adae-8c0be3f800b2': AppCreateSection,
  'ba83f9cc-20a9-4f2a-bdde-45caf9efac49': EditUserSection,
  'f522b59f-7bfd-4b6f-acfe-e259b91440d3': EditInputsFormSection, // 1 Inputs
  'c9373cfd-26b5-4bc4-8add-c8987f0a15a4': EditInputsFormSection, // 2 Inputs
  '1ec17af6-5f56-4fa3-8343-027d017c5342': EditInputsFormSection, // 3 Inputs
  '6a75bb25-1492-48e8-80a2-e1678e424acb': EditInputsFormSection, // 4 Inputs
  '83b00d21-f824-44bb-9313-b295ed35210f': TwoButtonCallApiSection,
  'bc503c6d-a3cb-4da7-b998-7e8b1e5a688f': BusinessLogicListViewSection,
  '60857454-31a9-4430-9596-2b74bc921fef': BusinessLogicCreateSection,
  'c67b06ab-2ecc-4f8c-b078-ebd5afa25264': BusinessLogicEditSection,
  'e02b0681-7bd0-43a4-b5fb-8e986ddb60e4': EventTriggerCreateSection,
  'def77128-42c1-4855-a1e0-d449f47ffdd2': EventTriggerEditSection,
  '6bd3f2b5-bd32-45aa-b6a8-ecdef08b92fa': HandlerCreateSection,
  '651c607d-4eab-4f05-958e-dede8f422aff': HandlerEditSection,
  '29cb023d-3138-4242-88bf-054ac7206568': SignalCreateSection,
  '545dbb6f-fc3b-4586-8b91-7157fcb4f5b9': SignalEditSection,
  'ca9d59b1-11ac-4f70-b9a1-ce11fe90d6ee': HandlerParameterCreateSection,
  '1e561303-5326-442b-9806-d70b08f63a15': HandlerParameterEditSection,
  '7a57def6-64e2-4bd9-a2bb-01ced8e6afad': BreadcrumbHeaderSection,
  '0910c381-fb71-4e25-968b-c0bb6cd93e1a': BannerSection,
  'db5ddb16-b545-4a19-a56a-de55bbd743cc': MapSection,
  'd0486481-7162-4b0c-b3b5-86b2e348cfec': HeaderNavbarSection,
  '039e5a9f-4f38-4fff-8c21-b4bc19967504': AccordionSection,
  '35e71e6a-dd84-4356-b0e1-dd63c93b4b27': MetadataSection,
  'c112b083-d358-43b1-945c-5dee2ba8a872': TextFromApiSection,
  '4e88f276-5d9b-40d5-bc32-51e1b67d735c': CollapseBoxSection,
  '9fe3edf1-d978-4bb5-9d93-d4db6de7d508': ForgetPasswordSection,
  '018d45de-58ef-797c-8515-873dbd879ef7': ResetPasswordSection,
  'b8dd4b12-1d9f-46d7-8621-b972598a0f6a': ContactFormSection,
  '0331d99d-01fa-4659-8768-05e76ed687cf': LosFormSection,
  '6003e507-b89b-4205-8fe7-23c8dd26d13f': CarouselSection,
  '0191c792-7246-70e0-b602-81ba72190556': DealsTableSection,
  '0191c7d3-b0b0-7563-a1a6-9f7cee7103e8': DealCreateSection,
  '0191d609-a655-79a0-97ba-e4fee253dcd5': DealDetailSection,
};
