import PropTypes from 'prop-types';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

const AnchorMenu = ({ items, onClickItem, anchor, setAnchor, ...props }) => {
  return (
    <Menu
      anchorEl={anchor}
      open={!!anchor}
      onClose={() => setAnchor(null)}
      {...props}
    >
      {(items || []).map((item) => {
        const { label, value } = item;
        return (
          <MenuItem onClick={() => Promise.resolve(onClickItem(value)).then(() => setAnchor(null))}>
            {label}
          </MenuItem>
        );
      })}
    </Menu>
  );
};

AnchorMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.any,
    }),
  ),
  onClickItem: PropTypes.func,
  anchor: PropTypes.object,
  setAnchor: PropTypes.func,
};

AnchorMenu.defaultProps = {
  items: [],
  onClickItem: () => {},
  anchor: null,
  setAnchor: () => {},
};

export default AnchorMenu;
