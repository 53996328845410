import qs from 'qs';
import pick from 'lodash/pick';
import { useMemo, Fragment } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Collapse from '@mui/material/Collapse';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';

const MobileMenu = ({ menuItems, extraButton, buttonLabel, buttonNavigateTo, open, setOpen, openDropdown, dropdownAnchorEl, getColor, closeDropdown, handleSubmenuClick, dropdownOptions }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = qs.parse((location.search || '').slice(1));
  const renderNavbarItems = useMemo(
    () => (menuItems || []).map((menuItem, idx) => {
      const { label: menuItemLabel, path, type, disabled } = menuItem;
      if (type === 2) {
        return !disabled ? (
          <MKBox
            key={`${idx}-${path}`}
            mx={1}
            display="flex"
            alignItems="center"
            onClick={() => setOpen(false)}
          >
            <Button
              to={`${path}?${qs.stringify(pick(queryParams, 'mode'))}`}
              variant="text"
              color={location.pathname === path ? 'dark' : 'secondary'}
              fontSize={16}
              size="small"
            >
              {menuItemLabel}
            </Button>
          </MKBox>
        ) : null;
      }
      return (
        <MKBox
          key={`${idx}-${path}`}
          mx={1}
          display="flex"
          alignItems="center"
        >
          <Button
            key={`${idx}-${path}`}
            onClick={(event) => openDropdown(menuItem, event)}
            variant="text"
            color={getColor(menuItem, 'dark', 'secondary')}
            fontSize={16}
            size="small"
          >
            {menuItemLabel}
          </Button>
          <Menu
            anchorEl={dropdownAnchorEl}
            open={Boolean(dropdownAnchorEl)}
            onClose={closeDropdown}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            {(dropdownOptions || []).map((subMenuItem, subIdx) => (
              <Fragment key={`${subIdx}-${subMenuItem.path}`}>
                <MenuItem
                  onClick={() => { handleSubmenuClick(subMenuItem.path); setOpen(false); }}
                >
                  {subMenuItem.label}
                </MenuItem>
                {/* Insert divider after each submenu item except for the last one */}
                {subIdx !== menuItem.menu_items.length - 1 && <Divider />}
              </Fragment>
            ))}
          </Menu>
        </MKBox>
      );
    }),
    [closeDropdown, dropdownAnchorEl, dropdownOptions, getColor, handleSubmenuClick, location.pathname, menuItems, openDropdown, queryParams, setOpen],
  );

  return (
    <Collapse in={Boolean(open)} timeout="auto" unmountOnExit>
      <MKBox width="calc(100% + 1.625rem)" my={2} ml={-2}>
        {renderNavbarItems}
      </MKBox>
      {extraButton ? (
        <Button
          onClick={() => { navigate(buttonNavigateTo || '/'); }}
          variant="gradient"
          color="info"
          size="medium"
          sx={{ borderRadius: '50', mb: '16px', mt: '-8px' }}
        >
          {buttonLabel || 'Button'}
        </Button>
      ) : null}
    </Collapse>
  );
};

MobileMenu.propTypes = {
  menuItems: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      path: PropTypes.string,
      disabled: PropTypes.bool,
    }),
  ),
  open: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]).isRequired,
  extraButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonNavigateTo: PropTypes.string,
  setOpen: PropTypes.func,
  openDropdown: PropTypes.func,
  dropdownAnchorEl: PropTypes.object,
  getColor: PropTypes.func,
  closeDropdown: PropTypes.func,
  handleSubmenuClick: PropTypes.func,
  dropdownOptions: PropTypes.array,
};

export default MobileMenu;
