import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getHandlers, deleteHandler } from 'api/handlers';
import DataTable from 'components/DataTable';

const HandlerListViewSection = () => {
  const handlerTableColumns = useMemo(() => [
    {
      field: 'handler_id',
      label: 'Handler ID',
    },
    { field: 'handler_name' },
  ], []);

  const [handlers, setHandlers] = useState([]);
  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const businessLogicId = searchParams.get('business_logic_id');

  const fetchHandlersFromApi = useCallback(() => {
    const params = {
      business_logic: businessLogicId,
    };
    return businessLogicId && getHandlers(params)
      .then(({ data }) => {
        setHandlers(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [businessLogicId, setAuth]);

  const onPressAdd = useCallback(() => {
    navigate(`/create_handler?business_logic_id=${businessLogicId}`);
  }, [navigate, businessLogicId]);

  const onPressEdit = useCallback((handlerId) => {
    navigate(`/handler?handler_id=${handlerId}&business_logic_id=${businessLogicId}&edit=true`);
  }, [businessLogicId, navigate]);

  const onPressDelete = useCallback((handlerId) => {
    return deleteHandler(handlerId)
      .then(() => {
        return fetchHandlersFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchHandlersFromApi, setAuth]);

  useEffect(() => {
    fetchHandlersFromApi();
  }, [fetchHandlersFromApi]);

  return (
    <MKBox my={2}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h5">Handlers</MKTypography>
      </MKBox>
      <DataTable
        data={handlers}
        columns={handlerTableColumns}
        idField="handler_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        selectable
      />
    </MKBox>
  );
};

export default HandlerListViewSection;
