import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useAuth } from 'contexts/auth';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKButton from 'components/MaterialKit/MKButton';
import TextInput from 'components/TextInput';
import { Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import { createDynamicTableRow } from 'api/sections';
import { handleErrorResponse } from 'utils/general';
import { getStoredAuth } from 'api';
import { getClientCredential } from 'api/auth';

const first_name_attribute_id = '91abb6be-a4c1-4cf0-bb4c-87215f96f0db';
const last_name_attribute_id = 'c591f533-d4ca-444f-9bf7-cbebceefa3bc';
const email_attribute_id = 'f79dd65f-90c8-4dc2-b0b3-1ef3ad0ee5b4';
const message_attribute_id = '32dbce74-3e7e-41e2-b86d-4eb6f9838a4b';

const initialValues = {
  '32dbce74-3e7e-41e2-b86d-4eb6f9838a4b': '',
  '91abb6be-a4c1-4cf0-bb4c-87215f96f0db': '',
  'c591f533-d4ca-444f-9bf7-cbebceefa3bc': '',
  'f79dd65f-90c8-4dc2-b0b3-1ef3ad0ee5b4': '',
};
const ContactFormSection = ({ storage_collection_definition_id, ...props }) => {
  const navigate = useNavigate();
  const [agreeTerms, setAgreeTerms] = useState(false);
  const { auth, setAuth } = useAuth();

  const handleChecked = useCallback(() => setAgreeTerms((val) => !val), []);

  const onSubmit = useCallback((values, { setFieldError, resetForm }) => {
    const createBody = {
      json_short_data: JSON.stringify(values),
    };
    return createDynamicTableRow(storage_collection_definition_id, createBody)
      .then(({ data }) => {
        alert('Your message has been sent successfully.');
        resetForm();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [storage_collection_definition_id, setAuth]);

  useEffect(() => {
    const storedAuth = getStoredAuth();
    if (!storedAuth) {
      getClientCredential()
        .then(({ data }) => {
          setAuth(data);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    } else {
      setAuth(storedAuth);
    }
  }, [setAuth]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched }) => {
        return (
          <MKBox width="100%" component="form" method="post" autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <TextInput
                  name={first_name_attribute_id}
                  label="First Name"
                  value={values[first_name_attribute_id]}
                  onChange={handleChange(first_name_attribute_id)}
                  onBlur={handleBlur(first_name_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextInput
                  name={last_name_attribute_id}
                  label="Last Name"
                  value={values[last_name_attribute_id]}
                  onChange={handleChange(last_name_attribute_id)}
                  onBlur={handleBlur(last_name_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={email_attribute_id}
                  type="email"
                  label="Email Address"
                  value={values[email_attribute_id]}
                  onChange={handleChange(email_attribute_id)}
                  onBlur={handleBlur(email_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={message_attribute_id}
                  label="Your Message"
                  value={values[message_attribute_id]}
                  onChange={handleChange(message_attribute_id)}
                  onBlur={handleBlur(message_attribute_id)}
                  multiline
                  rows={6}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12} alignItems="center" ml={-1}>
                <Switch checked={agreeTerms} onChange={handleChecked} />
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="white"
                  sx={{ cursor: 'pointer', userSelect: 'none' }}
                  onClick={handleChecked}
                >
                  &nbsp;&nbsp;I agree the&nbsp;
                </MKTypography>
                <MKTypography
                  component="a"
                  href="/legal/terms-and-conditions"
                  variant="button"
                  fontWeight="regular"
                  color="lightblue"
                  onClick={(e) => { navigate('/legal/terms-and-conditions'); e.preventDefault(); }}
                >
                  Terms and Conditions
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={12} my={2}>
              <MKButton
                variant="gradient"
                color="success"
                onClick={handleSubmit}
                type="submit"
                disabled={!agreeTerms}
                fullWidth
              >
                SEND MESSAGE
              </MKButton>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

ContactFormSection.propTypes = {
  storage_collection_definition_id: PropTypes.string.isRequired,
};

export default ContactFormSection;
