import { useCallback, useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Switch from '@mui/material/Switch';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import MKButton from 'components/MaterialKit/MKButton';
import TextInput from 'components/TextInput';
import { Formik } from 'formik';
import PropTypes from 'prop-types';
import { createDynamicTableRow } from 'api/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getStoredAuth } from 'api';
import { getClientCredential } from 'api/auth';

const organization_name_attribute_id = '7f403836-fd65-4420-aa0d-41a5d2bd3685';
const name_attribute_id = '3b3bad34-4584-4891-a1e0-b4cd025d6624';
const title_attribute_id = '351c8039-bced-4ab0-bac4-1a80538a06f9';
const email_attribute_id = 'e43e7245-c5c5-4e1a-88ad-0b5f53f56b00';
const business_website_attribute_id = '337b8f0b-379a-415a-9f4f-de53caa482cb';
const region_attribute_id = '15c87f01-d913-430b-b7be-b944dbe46d63';
const amount_of_financing_sought_attribute_id = '9d59470f-bb2d-4fe4-9e2a-d3644fc0c503';
const brief_project_description_and_financing_needs_attribute_id = '154f8bce-68bb-4e2e-a4bb-7248e9451ef9';

const initialValues = {
  '7f403836-fd65-4420-aa0d-41a5d2bd3685': '',
  '3b3bad34-4584-4891-a1e0-b4cd025d6624': '',
  '351c8039-bced-4ab0-bac4-1a80538a06f9': '',
  'e43e7245-c5c5-4e1a-88ad-0b5f53f56b00': '',
  '337b8f0b-379a-415a-9f4f-de53caa482cb': '',
  '15c87f01-d913-430b-b7be-b944dbe46d63': '',
  '9d59470f-bb2d-4fe4-9e2a-d3644fc0c503': '',
  '154f8bce-68bb-4e2e-a4bb-7248e9451ef9': '',
};
const LosFormSection = ({ storage_collection_definition_id, ...props }) => {
  const disclaimerText = () => {
    return (
      <>
        <p>By submitting this Eligibility Form, the applicant acknowledges and agrees to the following:</p>
        <p style={{ marginTop: '8px' }}>Universal Carbon Finance Group Limited (UCFINN) operates as a loan origination platform and not as a lender. All loan  decisions, terms, and conditions are subject to the individual discretion, criteria, and processes of the participating lenders on the  UCFINN platform. UCFINN merely facilitates the connection between potential borrowers and these lenders and holds no power  or influence over lending decisions. No Guarantee of Approval: Completing and submitting this form does not guarantee or imply  approval, offer, or any commitments from any lender associated with the UCFINN platform. This form is a preliminary review  designed to gauge alignment with various lending criteria of the associated lenders.All information provided in this form will be  used exclusively to facilitate the loan origination process. UCFINN may share this information with relevant lenders on its  platform for the purpose of loan evaluation. UCFINN adheres to all applicable data protection regulations and respects the  privacy of its applicants.</p>
        <p style={{ marginTop: '8px' }}>Consent to Process Confidential Information: The applicant hereby grants explicit consent for UCFINN to process, store, and  share the provided confidential information, including any financial, business, or personal data that may be deemed confidential,  with associated lenders on its platform for the express purpose of loan evaluation. UCFINN commits to maintaining stringent  data confidentiality standards and employs measures to safeguard the provided information.</p>
        <p style={{ marginTop: '8px' }}>By proceeding with this submission, the applicant acknowledges having read, understood, and agreed to the terms set out in this  disclaimer and consent.</p>
      </>
    );
  };

  const [agreeTerms, setAgreeTerms] = useState(false);
  const handleChecked = useCallback(() => setAgreeTerms((val) => !val), []);
  const { auth, setAuth } = useAuth();

  const onSubmit = useCallback((values, { setFieldError, resetForm }) => {
    const createBody = {
      json_short_data: JSON.stringify(values),
    };
    return createDynamicTableRow(storage_collection_definition_id, createBody)
      .then(({ data }) => {
        alert('Your message has been sent successfully.');
        resetForm();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [storage_collection_definition_id, setAuth]);

  useEffect(() => {
    const storedAuth = getStoredAuth();
    if (!storedAuth) {
      getClientCredential()
        .then(({ data }) => {
          setAuth(data);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    } else {
      setAuth(storedAuth);
    }
  }, [setAuth]);

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched }) => {
        return (
          <MKBox width="100%" component="form" method="post" autoComplete="off">
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <TextInput
                  name={organization_name_attribute_id}
                  label="Business / Organization Name"
                  value={values[organization_name_attribute_id]}
                  onChange={handleChange(organization_name_attribute_id)}
                  onBlur={handleBlur(organization_name_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={name_attribute_id}
                  label="Name"
                  value={values[name_attribute_id]}
                  onChange={handleChange(name_attribute_id)}
                  onBlur={handleBlur(name_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={title_attribute_id}
                  label="Title"
                  value={values[title_attribute_id]}
                  onChange={handleChange(title_attribute_id)}
                  onBlur={handleBlur(title_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={email_attribute_id}
                  label="Email"
                  value={values[email_attribute_id]}
                  onChange={handleChange(email_attribute_id)}
                  onBlur={handleBlur(email_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={business_website_attribute_id}
                  label="Business Website"
                  value={values[business_website_attribute_id]}
                  onChange={handleChange(business_website_attribute_id)}
                  onBlur={handleBlur(business_website_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={region_attribute_id}
                  label="Region"
                  value={values[region_attribute_id]}
                  onChange={handleChange(region_attribute_id)}
                  onBlur={handleBlur(region_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={amount_of_financing_sought_attribute_id}
                  label="Amount of financing sought"
                  value={values[amount_of_financing_sought_attribute_id]}
                  onChange={handleChange(amount_of_financing_sought_attribute_id)}
                  onBlur={handleBlur(amount_of_financing_sought_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextInput
                  name={brief_project_description_and_financing_needs_attribute_id}
                  label="Brief project description and financing needs"
                  value={values[brief_project_description_and_financing_needs_attribute_id]}
                  onChange={handleChange(brief_project_description_and_financing_needs_attribute_id)}
                  onBlur={handleBlur(brief_project_description_and_financing_needs_attribute_id)}
                  inputProps={{ style: { color: 'white' } }}
                  multiline
                  rows={6}
                />
              </Grid>
              <Grid item xs={12} alignItems="center">
                <MKBox
                  display="flex"
                  flexDirection="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  ml={-1}
                >
                  <Switch checked={agreeTerms} onChange={handleChecked} />
                  <MKTypography
                    variant="button"
                    fontWeight="regular"
                    color="white"
                    onClick={handleChecked}
                  >
                    Disclaimer and Consent
                  </MKTypography>
                </MKBox>
                <MKTypography
                  variant="button"
                  fontWeight="regular"
                  color="white"
                  onClick={handleChecked}
                >
                  {disclaimerText()}
                </MKTypography>
              </Grid>
            </Grid>
            <Grid container item justifyContent="center" xs={12} my={2}>
              <MKButton
                variant="gradient"
                color="success"
                onClick={handleSubmit}
                type="submit"
                disabled={!agreeTerms}
                fullWidth
                sx={{ mt: 2 }}
              >
                SUBMIT
              </MKButton>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

LosFormSection.propTypes = {
  storage_collection_definition_id: PropTypes.string.isRequired,
};

export default LosFormSection;
