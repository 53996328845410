import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const Carousel = ({
  items,
  settings,
  itemProps,
  ...props
}) => {
  const applySettings = {
    ...settings,
  };

  return (
    <Slider {...applySettings}>
      {items.map((item, index) => {
        return (
          <MKBox
            key={index}
            paddingX="5vw"
            sx={{ ...itemProps }}
          >
            {item}
          </MKBox>
        );
      })}
    </Slider>
  );
};

Carousel.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  settings: PropTypes.shape({}),
  itemProps: PropTypes.object,
};

export default Carousel;
