import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MKTypography from 'components/MaterialKit/MKTypography';

const createData1 = (field, value_in_original_units, value_in_ccu, additional_information) => {
  return { field, value_in_original_units, value_in_ccu, additional_information };
};

const dataRows1 = [
  createData1('Principal Amount', '€ 15,000,000', '187,500 CCU', 'Total loan amount expressed in Euros and CCUs.'),
  createData1('Collateral Value', '€ 20,000,000', '250,000 CCU', 'Value of secured assets in Euros and CCUs.'),
  createData1('Fees', '€ 500,000', '6,250 CCU', 'Fees related to transactions and processing.'),
  createData1('Energy Efficiency Scope', 'Level 2', '', 'Medium-term energy efficiency improvements.'),
  createData1('Emissions Scope', 'Level 2', '', 'Covers direct and indirect emissions from controlled sources.'),
  createData1('Emissions Calculations', '5,000 tonnes CO2 equivalent', '500,000 CCU', 'Calculated emission reductions over project lifecycle.'),
  createData1('Energy Savings', '3,000 MWh', '300,000 CCU', 'Energy savings in megawatt-hours and equivalent CCUs.'),
  createData1('Emissions Savings', '2,000 tonnes CO2 equivalent', '200,000 CCU', 'Reduction in CO2 emissions due to project activities.'),
  createData1('Monetized Cumulative Green Returns', '€ 4,000,000', '50,000 CCU', 'Total value of green benefits over time in Euros and CCUs.'),
  createData1('Monetized Green Yield (CCU p.a.)', '15% per annum', '', 'Annualized return from green investments as a percentage and CCUs.'),
];

const createData2 = (field, description, additional_information) => {
  return { field, description, additional_information };
};

const dataRows2 = [
  createData2('Total Direct Emissions (Scope 1)', 'Emissions from sources that are owned or controlled by the company.', '1,200 tonnes CO2e'),
  createData2('Total Indirect Emissions (Scope 2)', 'Emissions from the generation of purchased electricity consumed by the company.', '2,800 tonnes CO2e'),
  createData2('Other Indirect Emissions (Scope 3)', 'Emissions from sources not owned or directly controlled by the company but related to company activities.', '4,500 tonnes CO2e'),
  createData2('Total Emissions', 'Sum of all scopes of emissions.', '8,500 tonnes CO2e'),
  createData2('Emissions Reduction Target', 'The target set by the company for reducing emissions over a specified timeline.', '30% reduction by 2030'),
  createData2('Emissions Reduction Achieved', 'Actual reduction in emissions compared to a baseline year.', '15% reduction since 2020'),
  createData2('Carbon Offset Initiatives', 'Initiatives taken to offset emissions, such as investment in renewable energy or reforestation projects.', 'Invested in 500 hectares of reforestation'),
  createData2('Renewable Energy Usage', 'Percentage of energy consumption met through renewable energy sources., such as investment in renewable energy or reforestation projects.', '50% of total energy consumption'),
  createData2('Carbon Footprint Per Unit', 'Carbon emissions per unit of production or activity.', '0.015 tonnes CO2e per MWh generated'),
  createData2('Regulatory Compliance', 'Compliance with local and international environmental regulations regarding emissions.', 'Fully compliant with EU regulations'),
  createData2('Third-Party Verification', 'Status of emissions reports and data verified by an independent third-party.', 'Verified by GreenCert International'),
];

const createData3 = (category, description, amount, amount_in_ccu) => {
  return { category, description, amount, amount_in_ccu };
};

const dataRows3 = [
  createData3('Liabilities (Emissions & Usage)', '', '', ''),
  createData3('Total Direct Emissions (Scope 1)', 'Emissions from company-controlled sources', '1,200 tonnes CO2e', '120,000 CCUs'),
  createData3('Total Indirect Emissions (Scope 2)', 'Emissions from purchased electricity', '2,800 tonnes CO2e', '280,000 CCUs'),
  createData3('Other Indirect Emissions (Scope 3)', 'Emissions from non-controlled sources', '4,500 tonnes CO2e', '450,000 CCUs'),
  createData3('Total Emissions', 'Sum of all emissions', '8,500 tonnes CO2e', '850,000 CCUs'),
  createData3('Assets (Reductions & Savings)', '', '', ''),
  createData3('Emissions Reduction Achieved', 'Reduction compared to baseline year', '1,275 tonnes CO2e (15% of total)', '127,500 CCUs'),
  createData3('Energy Savings', 'Energy saved through efficiency measures', '3,000 MWh', '75,000 CCUs'),
  createData3('Carbon Offset Initiatives', 'Contribution from reforestation and others', '2,000 tonnes CO2e', '200,000 CCUs'),
  createData3('Renewable Energy Usage', 'Reduction in emissions due to renewable use', '3,000 MWh', '75,000 CCUs'),
  createData3('Total Reductions & Savings', 'Sum of all positive environmental impacts', '6,275 tonnes CO2e + 3,000 MWh', '477,500 CCUs'),
];

const DealCarbonAccounting = ({ ...props }) => {
  return (
    <MKBox>
      <MKTypography color="black" marginBottom="1rem">1. Summary</MKTypography>
      <TableContainer style={{ marginBottom: '5rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: 'table-header-group', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell width="20%" align="center">Field</TableCell>
              <TableCell align="center" width="20%">Value in Original Units</TableCell>
              <TableCell align="center" width="30%">Value in CCU (Carbon Credit Units)</TableCell>
              <TableCell align="center" width="30%">Additional Information</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows1.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>{row.field}</TableCell>
                <TableCell>{row.value_in_original_units}</TableCell>
                <TableCell>{row.value_in_ccu}</TableCell>
                <TableCell>{row.additional_information}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MKTypography color="black" marginBottom="1rem">2. Carbon Accounting</MKTypography>
      <TableContainer style={{ marginBottom: '5rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: 'table-header-group', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell width="25%" align="center">Field</TableCell>
              <TableCell align="center" width="45%">Description</TableCell>
              <TableCell align="center" />
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows2.map((row) => (
              <TableRow
                key={row.name}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>{row.field}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.additional_information}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MKTypography color="black" marginBottom="1rem">3. Carbon Balance Sheet</MKTypography>
      <TableContainer style={{ marginBottom: '5rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: 'table-header-group', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell width="20%" align="center">Category</TableCell>
              <TableCell align="center" width="20%">Description</TableCell>
              <TableCell align="center" width="20%">Amount (tonnes CO2e or MWh)</TableCell>
              <TableCell align="center" width="20%">Amount in CCUs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows3.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  '&:last-child td': { border: 0 },
                  '&:last-child th': { fontWeight: 'bold', border: 0 },
                  '&:nth-child(1) th': { fontWeight: 'bold' },
                  '&:nth-child(5) th': { fontWeight: 'bold', borderBottom: 1 },
                  '&:nth-child(5) td': { borderBottom: 1 },
                  '&:nth-child(6) th': { fontWeight: 'bold' },
                }}
              >
                <TableCell width="25%" component="th" scope="row">{row.category}</TableCell>
                <TableCell width="35%">{row.description}</TableCell>
                <TableCell>{row.amount}</TableCell>
                <TableCell>{row.amount_in_ccu}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MKBox>
  );
};

DealCarbonAccounting.propTypes = {

};

DealCarbonAccounting.defaultProps = {
};

export default DealCarbonAccounting;
