import { Fragment, useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { fetchSectionProps, renderSectionComponent } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import { getPublicSections } from 'api/sections';
import { useAuth } from 'contexts/auth';

const FlexBoxSection = ({ items, direction, justify_content, align_items, item_props, editable, onPressEdit, ...props }) => {
  const [subSections, setSubSections] = useState([]);
  const [subSectionsProps, setSubSectionsProps] = useState({});
  const { auth, setAuth } = useAuth();

  const fetchSectionsFromApi = useCallback((sectionIds) => {
    const sectionParams = {
      'section_id[in]': sectionIds.join(','),
      '$expand': 'section_definition/collection_definition/attributes',
    };
    return getPublicSections(sectionParams)
      .then(({ data }) => {
        setSubSections(data);
      });
  }, []);

  useEffect(() => {
    const sectionIds = (items || []).reduce((ids, item) => {
      const sections = item.sections || (item.section ? [item.section] : []);
      if (sections) {
        return [...ids, ...sections];
      }
      return ids;
    }, []);
    if (sectionIds.length > 0) {
      fetchSectionsFromApi(sectionIds, !auth.user)
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [items, fetchSectionsFromApi, auth.user, setAuth]);

  useEffect(() => {
    (subSections || []).forEach((subSection) => {
      const { section_id } = subSection;
      return fetchSectionProps(subSection, !auth.user)
        .then((subSectionProps) => {
          setSubSectionsProps((oriSubSectionsProps) => {
            const updatedSubSectionsProps = { ...oriSubSectionsProps };
            updatedSubSectionsProps[section_id] = subSectionProps;
            return updatedSubSectionsProps;
          });
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    });
  }, [subSections, auth.user, setAuth]);

  return (
    <MKBox display="flex" flexDirection={direction} height="100%" justifyContent={justify_content} alignItems={align_items} {...props}>
      {(items || []).map((item, i) => {
        const { sections, section, ...extraProps } = item;
        const sectionIds = sections || (section ? [section] : []);
        return (
          <MKBox key={i} display="flex" flexDirection={direction === 'row' ? 'column' : 'row'} {...item_props} {...extraProps}>
            {(sectionIds || []).map((sectionId, j) => {
              const subSection = subSections.find((s) => s.section_id === sectionId);
              return subSection ? (
                <Fragment key={`${sectionId}-${j}`}>
                  {renderSectionComponent(subSection, subSectionsProps[sectionId], editable, onPressEdit)}
                </Fragment>
              ) : null;
            })}
          </MKBox>
        );
      })}
    </MKBox>
  );
};

FlexBoxSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  direction: PropTypes.oneOf(['row', 'column']),
  justify_content: PropTypes.string,
  align_items: PropTypes.string,
  item_props: PropTypes.object,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default FlexBoxSection;
