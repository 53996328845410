import { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MKBox from 'components/MaterialKit/MKBox';
import { handleErrorResponse } from 'utils/general';
import { useGlobalData } from 'contexts/global-data';
import { useAuth } from 'contexts/auth';
import { getStoredAuth } from 'api';
import { getObjectTypes, getPublicObjectTypes } from 'api/object_types';
import { getMe } from 'api/users';
import theme from 'assets/theme';
import routes from 'routes';

const AuthRedirectior = ({ required, to, children }) => {
  const location = useLocation();
  const { auth } = useAuth();
  if (required && Object.prototype.hasOwnProperty.call(required, 'auth')) {
    if (required.auth) {
      if (!auth.user) {
        return <Navigate to={`${to}?redirect=${location.pathname}${location.search}`} />;
      }
    } else if (auth.user) {
      return <Navigate to="/" />;
    }
  }
  return children;
};

AuthRedirectior.propTypes = {
  required: PropTypes.shape({
    auth: PropTypes.bool,
  }),
  to: PropTypes.string,
  children: PropTypes.node,
};

const App = () => {
  const { pathname } = useLocation();
  const { auth, setAuth, setAuthUser } = useAuth();
  const { setObjectTypes } = useGlobalData();

  const fetchObjectTypesFromApi = useCallback(() => {
    const objectTypeParams = {
      $orderBy: 'object_type_id',
    };
    return (auth.user ? getObjectTypes(objectTypeParams) : getPublicObjectTypes(objectTypeParams))
      .then(({ data }) => {
        setObjectTypes(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [auth.user, setObjectTypes, setAuth]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  useEffect(() => {
    const storedAuth = getStoredAuth();
    if (get(storedAuth, 'access_token') && get(storedAuth, 'user')) {
      setAuth(storedAuth);
      getMe({ $expand: 'role/scopes' })
        .then(({ data: user }) => {
          const updatedAuth = { ...getStoredAuth(), user };
          setAuth(updatedAuth);
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [setAuth, setAuthUser]);

  useEffect(() => {
    fetchObjectTypesFromApi();
  }, [fetchObjectTypesFromApi]);

  const getRoutes = useMemo(() => (allRoutes) => {
    return allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            key={route.name}
            path={route.route}
            element={(
              <AuthRedirectior required={route.required} to="/login">
                {route.component}
              </AuthRedirectior>
            )}
          />
        );
      }

      return null;
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <MKBox display="flex" flexDirection="column" height={1}>
        <Routes>
          {getRoutes(routes)}
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </MKBox>
    </ThemeProvider>
  );
};

export default App;
