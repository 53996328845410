import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import InputField from 'components/InputField';
import { isValidEmail } from 'utils/form';

const getFormValidation = (values) => {
  const { email } = values;
  const errors = {};
  if (!email) {
    errors.email = 'Required';
  } else if (!isValidEmail(email)) {
    errors.email = 'Invalid Email';
  }
  return errors;
};

const ForgetPasswordForm = ({ onForgetPassword,
  initialValues,
  submitButtonColor,
  submitButtonTitle,
  submitTextColor,
  backButtonColor,
  backButtonTitle,
  backTextColor,
  emailPlaceholder,
  emailLabel,
  ...props }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onForgetPassword}
      validate={getFormValidation}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched, setFieldValue }) => {
        return (
          <MKBox
            component="form"
            role="form"
            onSubmit={handleSubmit}
            style={{
              padding: '0px',
              fontSize: '16px',
            }}
          >
            <Grid mb="30px">
              <MKTypography color="black" fontSize="18px">
                Forgot Password: Step 1
              </MKTypography>
            </Grid>
            <Grid mb="30px">
              <MKTypography color="black" fontSize="16px">
                Please enter your email address and we will send you info to reset password
              </MKTypography>
            </Grid>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={6} md={4} xl={4}>
                <MKTypography
                  variant="body1"
                  color="black"
                  fontSize="16px"
                >
                  Email address
                </MKTypography>
              </Grid>
              <Grid item xs={6} md={8} xl={8} alignItems="center">
                <InputField
                  name="email"
                  value={values.email || ''}
                  type="text"
                  label={emailLabel}
                  placeholder={emailPlaceholder}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={touched.display_name && !!errors.display_name}
                />
                {touched.email && !!errors.email && (
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {errors.email}
                  </MKTypography>
                )}
              </Grid>
            </Grid>
            {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
            )}
            <Grid container mt="20vh" justifyContent="space-between" color="red">
              <Button color={backButtonColor} size="large" disabled={isSubmitting} style={{ border: '1px solid black', padding: '0px 50px' }}>
                <MKTypography color={backTextColor}>
                  {backButtonTitle}
                </MKTypography>
              </Button>
              <Button type="submit" color={submitButtonColor} size="large" disabled={isSubmitting} style={{ border: '1px solid black', padding: '0px 50px' }}>
                <MKTypography color={submitTextColor}>
                  {submitButtonTitle}
                </MKTypography>
              </Button>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

ForgetPasswordForm.propTypes = {
  onForgetPassword: PropTypes.func,
  initialValues: PropTypes.object,
  submitButtonColor: PropTypes.string,
  submitButtonTitle: PropTypes.string,
  submitTextColor: PropTypes.string,
  backButtonColor: PropTypes.string,
  backButtonTitle: PropTypes.string,
  backTextColor: PropTypes.string,
  emailLabel: PropTypes.string,
  emailPlaceholder: PropTypes.string,
};

ForgetPasswordForm.defaultProps = {
  onForgetPassword: () => {},
  initialValues: {},
};

export default ForgetPasswordForm;
