import api from '..';

export const getFile = (id, params) => {
  return api.get(`/files/${id}`, params, 'bearer');
};

export const updateFile = (id, body) => {
  return api.put(`/files/${id}`, body, 'bearer');
};

export const createFile = (body) => {
  return api.post('/files', body, 'bearer');
};

export const deleteFile = (id) => {
  return api.delete(`/files/${id}`, 'bearer');
};

export const createBigFile = (body) => {
  return api.postMultipart('/big_files', body, 'bearer');
};
