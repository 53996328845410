import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MKTypography from 'components/MaterialKit/MKTypography';

const createData1 = (structuring_term, value, description) => {
  return { structuring_term, value, description };
};

const dataRows1 = [
  createData1('Loan Amount', '€15 million', '17 years from project completion'),
  createData1('Repayment Schedule', 'Construction Financing (Interest-Only Period – 3 years)\nOperational Financing (Equal Amortization Period – 17 years)', 'Phase 1 (Years 1-3): Interest-only payments of €450,000 per year during construction.\nPhase 2 (Years 4-20): Amortized payments of €1,139,288 per year over the remaining 17 years.'),
  createData1('Equity Contribution', '€6 million', 'Minimum equity required from project sponsors, representing 30% of the total project cost of €20 million.'),
  createData1('Seniority', 'Senior debt', 'The loan has priority over all other debts and equity in terms of repayment and claims on assets.'),
  createData1('Capitalized Energy Savings', '€410,000 annually', 'Annual energy savings factored into financial projections to enhance borrowing capacity.'),
  createData1('Capitalized Energy Savings over life-time', '€8,200, 000', 'Total energy savings available for borrowing capacity (not time-adjusted)'),
  createData1('Encumbered Revenues', '25% of annual revenue', "A quarter of the project's annual revenues earmarked to service the loan."),
  createData1('Trade Credit Enhancements and Supply Chain Integration', '90 days credit terms', 'Extended credit terms with key suppliers like SolarTech Inc. and GreenMaterials Co. to reduce working capital needs.'),
  createData1('Securitization', 'N/A', 'Open option'),
  createData1('Syndication', 'N/A', 'N/A'),
  createData1('Sale-Leaseback', 'Open option', 'Opportunity for a sale-leaseback agreement valued at approximately €12 million upon project completion.'),
  createData1('Risk Management and Mitigation', '€20 million insurance', 'Comprehensive insurance covering construction and operational risks, plus €2 million in reserves for contingencies.'),
  createData1('Energy Performance-Share', '20% of excess savings', 'Lender receives 20% of financial returns from energy savings exceeding forecasts by more than 10%.'),
  createData1('Sustainability Reporting Requirements', 'Biannual reports', 'Detailed biannual sustainability reports to be prepared by an accredited third party.'),
  createData1('Regulatory Compliance Assurance', 'Regular audits', 'Compliance with EU building and environmental regulations verified through regular audits.'),
  createData1('Performance Milestones', 'Linked to funding disbursement', 'Funds disbursed based on construction milestones: initiation, structure completion, lock-up, and finishing.'),

];

const createData2 = (structuring_term, phase_1, phase_2) => {
  return { structuring_term, phase_1, phase_2 };
};

const dataRows2 = [
  createData2('Loan Amount', '€10 million', '€5 million'),
  createData2('Duration', 'Up to 3 years', '17 years from project completion'),
  createData2('Loan Disbursement', 'Disbursed in tranches: 25% at each major milestone', 'Full amount converted to term loan upon project completion'),
  createData2('Interest Rate', 'EURIBOR + 3% (fixed)', 'EURIBOR + 2% (floating), adjusted quarterly'),
  createData2('Repayment Terms', 'Interest-only payments during construction', 'Amortized payments start one year after completion'),
  createData2('Collateral', 'First lien on under-construction assets, materials, and equipment', 'First lien on operational building, land, plus revenue streams'),
  createData2('Guarantees', 'Performance bond from contractor (10% of loan amount)', 'Operational performance guarantees from project sponsors'),
];

const createData3 = (structuring_term, value_in_ccu, description) => {
  return { structuring_term, value_in_ccu, description };
};

const dataRows3 = [
  createData3('Loan Amount', '187,500 CCUs', 'Total loan amount in CCUs (based on €15,000,000).'),
  createData3('Repayment Schedule (Phase 1)', '5,625 CCUs (per year)', 'Interest-only payments during construction (3 years, €450,000 annually).'),
  createData3('Repayment Schedule (Phase 2)', '14,241 CCUs (per year)', 'Amortized payments over 17 years (€1,139,288 per year).'),
  createData3('Equity Contribution', '75,000 CCUs', 'Minimum equity contribution of €6,000,000.'),
  createData3('Capitalized Energy Savings (annual)', '5,125 CCUs', 'Annual energy savings converted from €410,000.'),
  createData3('Capitalized Energy Savings (lifetime)', '102,500 CCUs', "Energy savings over the project's 20-year lifetime (€8,200,000)."),
  createData3('Collateral Value', '250,000 CCUs', 'Value of secured real estate collateral (€20,000,000).'),
  createData3('Encumbered Revenues', '50,000 CCUs (per year)', '25% of annual revenues (€2,000,000 annually).'),
  createData3('Risk Management and Mitigation', '250,000 CCUs', 'Comprehensive insurance (€20,000,000).'),
  createData3('Energy Performance-Share', '1,250 CCUs (per year)', 'Lender receives 20% of financial returns from energy savings exceeding forecasts (€100,000 in excess savings).'),
  createData3('Sustainability Reporting', 'N/A', 'Biannual reports on sustainability performance by third parties.'),
];

const createData4 = (structuring_term, phase_1, phase_2, description) => {
  return { structuring_term, phase_1, phase_2, description };
};

const dataRows4 = [
  createData4('Loan Amount', '125,000 CCUs', '62,500 CCUs', 'Phase 1 loan: €10,000,000; Phase 2 loan: €5,000,000.'),
  createData4('Interest Rate', 'Carbon Yield Reference + 3% (fixed)', 'Carbon Yield Reference + 2% (floating), adjusted quarterly', ''),
  createData4('Duration', '3 years', '17 years', 'Duration for construction and operational phases.'),
  createData4('Loan Disbursement', '31,250 CCUs per milestone', 'Full conversion at completion', 'Disbursed in tranches based on milestones in Phase 1.'),
  createData4('Interest Rate', 'Carbon Yield Reference + 3% (fixed)', 'Carbon Yield Reference + 2% (floating)', 'Fixed during construction, floating during operations.'),
  createData4('Repayment Terms', '5,625 CCUs (per year)', '14,241 CCUs (per year)', 'Interest-only payments in Phase 1; amortized payments in Phase 2 (€1,139,288 annually).'),
  createData4('Collateral', '125,000 CCUs (under-construction assets)', '125,000 CCUs (building and land)', 'Collateral in Phase 1: under-construction assets; in Phase 2: operational assets.'),
  createData4('Guarantees', '12,500 CCUs', '6,250 CCUs', 'Performance bond (10% of loan amount).'),
];

const DealStructuring = ({ ...props }) => {
  return (
    <MKBox>
      <MKTypography color="black" marginBottom="1rem">1. Structuring Terms</MKTypography>
      <TableContainer style={{ marginBottom: '5rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: 'table-header-group', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell align="center">Structuring Term</TableCell>
              <TableCell align="center">Value</TableCell>
              <TableCell align="center">Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows1.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: 'bold' }}
                >
                  {row.structuring_term}
                </TableCell>
                <TableCell>{row.value}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MKTypography color="black" marginBottom="1rem">2. Staged Structuring</MKTypography>
      <TableContainer style={{ marginBottom: '5rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: 'table-header-group', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell align="center">Structuring Term</TableCell>
              <TableCell align="center">Phase 1: Construction Financing</TableCell>
              <TableCell align="center">Phase 2: Operational Financing</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows2.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: 'bold' }}
                >
                  {row.structuring_term}
                </TableCell>
                <TableCell>{row.phase_1}</TableCell>
                <TableCell>{row.phase_2}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MKTypography color="black" marginBottom="1rem">3. CCU Terms Summary</MKTypography>
      <TableContainer style={{ marginBottom: '5rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: 'table-header-group', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell align="center">Structuring Term</TableCell>
              <TableCell align="center" width="20%">Value in CCUs</TableCell>
              <TableCell align="center">Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows3.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: 'bold' }}
                >
                  {row.structuring_term}
                </TableCell>
                <TableCell>{row.value_in_ccu}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MKTypography color="black" marginBottom="1rem">4. CCU Staged Structuring</MKTypography>
      <TableContainer style={{ marginBottom: '5rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: 'table-header-group', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell align="center">Structuring Term</TableCell>
              <TableCell align="center">Phase 1: Construction Financing</TableCell>
              <TableCell align="center">Phase 2: Operational Financing</TableCell>
              <TableCell align="center">Description</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows4.map((row) => (
              <TableRow
                key={row.name}
                sx={{
                  '&:last-child td, &:last-child th': { border: 0 },
                }}
              >
                <TableCell
                  component="th"
                  scope="row"
                  style={{ fontWeight: 'bold' }}
                >
                  {row.structuring_term}
                </TableCell>
                <TableCell>{row.phase_1}</TableCell>
                <TableCell>{row.phase_2}</TableCell>
                <TableCell>{row.description}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MKBox>
  );
};

DealStructuring.propTypes = {

};

DealStructuring.defaultProps = {
};

export default DealStructuring;
