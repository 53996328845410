import { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const EditSignalForm = ({ signal, isEdit, onSave, isDecisionHandler, ...props }) => {
  const initialValues = useMemo(() => ({
    signal_name: signal?.signal_name || '',
    signal_type: signal?.signal_type || '',
    sequence: signal?.sequence || '',
    decision_type: signal?.decision_type || '',
  }), [signal]);
  const [decisionType, setDecisionType] = useState(null);
  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty, setFieldValue }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <MKInput
                  name="signal_name"
                  label="Signal Name"
                  value={values.signal_name || ''}
                  onChange={handleChange('signal_name')}
                  onBlur={handleBlur('signal_name')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="sequence"
                  label="Sequence"
                  value={values.sequence || ''}
                  onChange={handleChange('sequence')}
                  onBlur={handleBlur('sequence')}
                  fullWidth
                />
              </Grid>
              {isDecisionHandler && (
                <Grid container item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="decision_type_label">Decision Type</InputLabel>
                    <Select
                      labelId="decision_type_label"
                      id="decision_type_select"
                      value={decisionType}
                      label="Decision Type"
                      onChange={(e) => {
                        setFieldValue('decision_type', e.target.value);
                        setDecisionType(e.target.value);
                      }}
                      onBlur={handleBlur('handler_type')}
                    >
                      <MenuItem disabled value="">
                        Decision Type
                      </MenuItem>
                      <MenuItem value>True</MenuItem>
                      <MenuItem value={false}>False</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              )}
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditSignalForm.propTypes = {
  signal: PropTypes.shape({
    signal_name: PropTypes.string,
    signal_type: PropTypes.string,
    sequence: PropTypes.number,
    decision_type: PropTypes.bool,
  }),
  isDecisionHandler: PropTypes.bool,
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
};

EditSignalForm.defaultProps = {
  signal: {},
  isDecisionHandler: false,
  isEdit: false,
  onSave: () => { },
};

export default EditSignalForm;
