import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import DataTable from 'components/DataTable';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'contexts/auth';
import { withLoading } from 'utils/hoc';
import { handleErrorResponse } from 'utils/general';
import { dealsTableColumns } from './tablecolumns';

const staticData = [
  {
    deal_id: 'test_id',
    name: 'EcoBuild Corp',
    deal_type: 'Green Buildings',
    financing_amount: '15,000,000',
    currency: 'EUR',
    contract_date: '9/1/2024',
    maturity_date: '120',
    status: 'Approved',
  },
  {
    deal_id: 'test_id2',
    name: 'Green Energy Solutions',
    deal_type: 'Energy Transition',
    financing_amount: '3,500,000',
    currency: 'USD',
    contract_date: '8/15/2024',
    maturity_date: '96',
    status: 'Pending',
  },
];

const DealsTableSection = ({
  background_color,
  page_options,
  addable,
  editable,
  deletable,
  edit_data_path,
  add_button_label,
  edit_button_label,
  delete_button_label,
  setLoading,
  ...props
}) => {
  const { state } = useLocation();
  const [tableData, setTableData] = useState(staticData);
  console.log(tableData)

  useEffect(() => {
    if (state?.new_deal) {
      setTableData([...tableData, state?.new_deal]);
    }
  }, [state?.new_deal]);

  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const [rowsData, setRowsData] = useState(null);
  const [pageSize, setPageSize] = useState(page_options?.length ? page_options[0] : 10);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalRows, setTotalRows] = useState(0);
  const [sortBy, setSortBy] = useState(null);
  const [sortDirection, setSortDirection] = useState('asc');
  // const fetchFromApi = useCallback(() => {
  //   const dataParams = {
  //     ...extra_params,
  //     ...(sortBy ? {
  //       $orderBy: `${sortBy} ${sortDirection}`,
  //     } : {}),
  //     $skip: currentPage * pageSize,
  //     $top: pageSize,
  //   };
  //   setLoading(true);
  //   return getData(`/${table_name}`, dataParams, 'bearer')
  //     .then(({ data, headers }) => {
  //       const contentRange = get(headers, 'content-range');
  //       const size = Number(contentRange.split('/')[1]);
  //       setRowsData(data);
  //       setTotalRows(size);
  //     })
  //     .catch((err) => {
  //       handleErrorResponse(err, setAuth);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [table_name, extra_params, currentPage, pageSize, sortBy, sortDirection, setLoading, setAuth]);

  // const deleteFromApi = useCallback((dataId) => {
  //   setLoading(true);
  //   return deleteDatum(`/${table_name}`, dataId, 'bearer')
  //     .then(() => {
  //       return fetchFromApi();
  //     })
  //     .catch((err) => {
  //       handleErrorResponse(err, setAuth);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // }, [table_name, fetchFromApi, setLoading, setAuth]);

  const onClickAdd = useCallback(() => {
    // if (add_data_path) {
    navigate('create_deal');
    // }
  }, [navigate]);

  const onClickEdit = useCallback((dataId) => {
    // if (edit_data_path) {
    navigate(`deal_detail?deal_id=${dataId}`);
    // }
  }, [navigate]);

  const onClickSort = useCallback((headerName) => {
    setSortBy(headerName);
    setSortDirection((previousSortDirection) => (previousSortDirection === 'asc' ? 'desc' : 'asc'));
  }, []);

  return (
    <MKBox
      px={{ xs: 0, md: 1 }}
      py={{ xs: 1, md: 2 }}
      bgColor={background_color}
      {...props}
    >
      {/* <FetchingTable
        table_name="deals"
        id_field="deal_id"
        page_options={page_options}
        columns={dealsTableColumns}
        add_data_path="/solutions/add"
        edit_data_path="/solutions/edit"
        addable={addable}
        editable={editable}
        deletable={deletable}
        add_button_label={add_button_label}
        edit_button_label={edit_button_label}
        delete_button_label={delete_button_label}
      /> */}

      <DataTable
        idField="deal_id"
        data={tableData}
        columns={dealsTableColumns}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
        rowsPerPage={pageSize}
        rowsPerPageOptions={page_options}
        totalCount={totalRows}
        onRowsPerPageChange={setPageSize}
        sortBy={sortBy}
        sortDirection={sortDirection}
        onSort={onClickSort}
        onClickRow={onClickEdit}
        onPressAdd={onClickAdd}
        onPressEdit={onClickEdit}
        // onPressDelete={deletable ? deleteFromApi : null}
        addButtonLabel={add_button_label}
        editButtonLabel={edit_button_label}
        deleteButtonLabel={delete_button_label}
        selectable
        // {...props}
      />
    </MKBox>
  );
};

DealsTableSection.propTypes = {
  background_color: PropTypes.string,
  page_options: PropTypes.arrayOf(
    PropTypes.object,
  ),
  addable: PropTypes.bool,
  editable: PropTypes.bool,
  deletable: PropTypes.bool,
  edit_data_path: PropTypes.string,
  add_button_label: PropTypes.string,
  edit_button_label: PropTypes.string,
  delete_button_label: PropTypes.string,
  setLoading: PropTypes.func,
};

export default withLoading(DealsTableSection);
