import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import { useAuth } from 'contexts/auth';
import InputField from 'components/InputField';

export const dealTypes = [
  {
    value: 'green_buildings_and_construction',
    label: 'Green buildings and construction',
  },
  {
    value: 'decarbonization_projects',
    label: 'Decarbonization projects',
  },
  {
    value: 'energy_transition_projects_or_clean_enery_development',
    label: 'Energy transition projects or clean energy development',
  },
  {
    value: 'carbon_credit_bridge_loans',
    label: 'Carbon credit bridge loans',
  },
];

const EditDealForm = ({ deal, isEdit, onSave, ...props }) => {
  const initialValues = useMemo(() => ({
    deal_type: 'green_buildings_and_construction',
    organization_name: 'EcoTech Solutions Ltd.',
    name: 'Sarah Greene',
    title: 'Chief Sustainability Officer',
    email: 'sarah.greene@ecotech-solutions.com',
    business_website: 'www.ecotech-solutions.com',
    region: 'Europe (Central & Eastern Europe)',
    financing_amount: '€ 15,000,000',
    brief_description: 'EcoTech Solutions Ltd. is seeking financing to transition its manufacturing facilities to 100% renewable energy sources. The project involves the procurement and installation of solar panels and wind turbines across multiple sites in Slovakia and neighboring countries. This investment is expected to result in a 40% reduction in energy costs over the next decade. Additionally, by leveraging local incentives and European Union grants for green energy projects, EcoTech Solutions anticipates a 25% subsidy on initial costs, further enhancing the project’s financial feasibility. With an estimated payback period of 6 years and a projected increase in net profitability by 15% within 3 years, this project is a sound investment for long-term operational savings and sustainability.\n\nEcoTech Solutions Ltd. is seeking financing to transition its manufacturing facilities to 100% renewable energy sources. The project involves the procurement and installation of solar panels and wind turbines across multiple sites in Slovakia and neighboring countries. This investment is expected to result in a 40% reduction in energy costs over the next decade. Additionally, by leveraging local incentives and European Union grants for green energy projects, EcoTech Solutions anticipates a 25% subsidy on initial costs, further enhancing the project’s financial feasibility. With an estimated payback period of 6 years and a projected increase in net profitability by 15% within 3 years, this project is a sound investment for long-term operational savings and sustainability.',
    project_rationale: "This energy transition project not only aligns with EcoTech Solutions’ sustainability goals but also offers a compelling economic case for financing. The anticipated reduction in energy costs and the availability of subsidies and incentives enhance the project's financial attractiveness. The projected savings from reduced carbon emissions and energy efficiency directly translate into improved profitability, making the loan a low-risk investment for the bank. Moreover, the project contributes to the European Green Deal’s objectives, positioning the company as a leader in sustainable manufacturing. The bank’s support for this project will also enhance its portfolio in green finance, meeting growing demands for sustainable investments.",
  }), []);
  const { setAuth } = useAuth();
  const [dealType, setDealType] = useState('');

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty, setFieldValue }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="deal_type_label">Deal Type</InputLabel>
                  <Select
                    labelId="deal_type"
                    id="deal_type_label"
                    value={values.deal_type || dealType}
                    label="Deal Type"
                    onChange={(e) => {
                      setFieldValue('deal_type', e.target.value);
                      setDealType(e.target.value);
                    }}
                    onBlur={handleBlur('handler_type')}
                  >
                    <MenuItem disabled value="">
                      Deal Type
                    </MenuItem>
                    {dealTypes.map((type) => <MenuItem key={type.value} value={type.value}>{type.label}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="organization_name"
                  label="Business / Organization Name"
                  value={values.organization_name || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="name"
                  label="Name"
                  value={values.name || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="title"
                  label="Title"
                  value={values.title || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="email"
                  label="Email"
                  value={values.email || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="business_website"
                  label="Business Website"
                  value={values.business_website || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="region"
                  label="Region"
                  value={values.region || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="financing_amount"
                  label="Amount of Financing Sought"
                  value={values.financing_amount || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="brief_description"
                  label="Brief Project Description and Financing Needs"
                  value={values.brief_description || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fullWidth
                  type="textarea"
                />
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="business_presentation_file"
                  label="Business Presentation File"
                  value="EcoTech_Business_Presentation.pdf"
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="project_documentation_file"
                  label="Project Documentation File"
                  value="Renewable_Energy_Project_Plan.pdf"
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <InputField
                  name="project_rationale"
                  label="Carbon and Green Finance Project Rationale "
                  value={values.project_rationale || ''}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  fullWidth
                  type="textarea"
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting}
                  // disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditDealForm.propTypes = {
  deal: PropTypes.shape({
    // handler_name: PropTypes.string,
    // handler_type: PropTypes.string,
    // handler_sub_type: PropTypes.string,
    // sync: PropTypes.bool,
    // delay_time: PropTypes.number,
    // handler_signals: PropTypes.array,
    // function: PropTypes.string,
    // data: PropTypes.string,
    // expression: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
};

EditDealForm.defaultProps = {
  deal: {},
  isEdit: false,
  onSave: () => {},
};

export default EditDealForm;
