import PropTypes from 'prop-types';
import startCase from 'lodash/startCase';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { useAuth } from 'contexts/auth';
import MKBox from 'components/MaterialKit/MKBox';
import { Checkbox, Collapse, FormControl, FormControlLabel, FormGroup, FormLabel, Grid, InputLabel, MenuItem, Paper, Radio, RadioGroup, Select, Switch, Tab, Tabs } from '@mui/material';
import MKTypography from 'components/MaterialKit/MKTypography';
import InputField from 'components/InputField';
import DealCarbonAccounting from 'components/DealCarbonAccounting';
import DealStructuring from 'components/DealStructuring';
import DealAnalytics from 'components/DealAnalytics';

const actions = [
  { value: 'qualify', label: 'Qualify' },
  { value: 'decline', label: 'Decline' },
  { value: 'third_party_certifications', label: 'Third-party certifications' },
  { value: 'generate_term_sheet', label: 'Generate term sheet' },
];

const dealDetail = {
  deal_id: 'test_id',
  deal_type: 'green_buildings_and_construction',
  organization_name: 'EcoBuild Corp',
  name: 'Sarah Greene',
  title: 'Chief Sustainability Officer',
  email: 'sarah.greene@ecotech-solutions.com',
  business_website: 'www.ecotech-solutions.com',
  region: 'Europe (Central & Eastern Europe)',
  financing_amount: '€ 3,200,000',
  project_description: 'EcoBuild Corp is developing a 12-story commercial office building in central Berlin, Germany. The project aims to create a high-performance, energy-efficient structure that meets the highest sustainability standards. The building will feature cutting-edge green technologies, including advanced energy management systems, renewable energy generation, and water conservation measures. The project is positioned as a landmark in sustainable urban development, with a strong focus on minimizing the carbon footprint and maximizing energy efficiency.',
  estimated_project_cost: '€ 20,000,000',
  requested_loan_amount: '€ 15,000,000',
  project_timeline: '',
  start_date: '3/1/2025',
  completion_date: '9/30/2027',
  project_rationale: "This energy transition project not only aligns with EcoTech Solutions’ sustainability goals but also offers a compelling economic case for financing. The anticipated reduction in energy costs and the availability of subsidies and incentives enhance the project's financial attractiveness. The projected savings from reduced carbon emissions and energy efficiency directly translate into improved profitability, making the loan a low-risk investment for the bank. Moreover, the project contributes to the European Green Deal’s objectives, positioning the company as a leader in sustainable manufacturing. The bank’s support for this project will also enhance its portfolio in green finance, meeting growing demands for sustainable investments.",
};

const CustomTabPanel = (props) => {
  const { children, value, tabValue, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== tabValue}
      id={`simple-tabpanel-${tabValue}`}
      aria-labelledby={`simple-tab-${tabValue}`}
      {...other}
    >
      {value === tabValue && <MKBox sx={{ p: 3 }}>{children}</MKBox>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  tabValue: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

const certificationLabel = {
  leed: 'LEED',
  breem: 'BREEAM',
  energy_star: 'ENERGY STAR',
  green_globes: 'Green Globes',
  passive_house: 'Passive House',
  well_building_standard: 'WELL Building Standard',
  living_building_challenge: 'Living Building Challenge',
}

const DealDetailSection = ({ ...props }) => {
  const [selectedProjectType, setSelectedProjectType] = useState('a');
  const handleProjectTypeChange = (event) => {
    setSelectedProjectType(event.target.value);
  };

  const { auth, setAuth } = useAuth();
  const [actionValue, setActionValue] = useState(0);
  const [tabValue, setTabValue] = useState('details');
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const [businessInfoCollapsed, setBusinessInfoCollapsed] = useState(false);
  const [projectInfoCollapsed, setProjectInfoCollapsed] = useState(false);
  const [greenBuildingCertificationsCollapsed, setGreenBuildingCertificationsCollapsed] = useState(false);
  const [certificationLevelCollapsed, setCertificationLevelCollapsed] = useState(false);
  const [technologiesCollapsed, setTechnologiesCollapsed] = useState(false);
  const [preferredTechnologySuppliersCollapsed, setPreferredTechnologySuppliersCollapsed] = useState(false);
  const [energyModelingCollapsed, setEnergyModelingCollapsed] = useState(false);
  const [economicsCollapsed, setEconomicsCollapsed] = useState(false);

  const handleCollapseChange = (section) => {
    switch (section) {
      case 'business_info':
        setBusinessInfoCollapsed((prev) => !prev);
        break;
      case 'project_info':
        setProjectInfoCollapsed((prev) => !prev);
        break;
      case 'green_building_certifications':
        setGreenBuildingCertificationsCollapsed((prev) => !prev);
        break;
      case 'certification_level':
        setCertificationLevelCollapsed((prev) => !prev);
        break;
      case 'technologies':
        setTechnologiesCollapsed((prev) => !prev);
        break;
      case 'preferred_technology_suppliers':
        setPreferredTechnologySuppliersCollapsed((prev) => !prev);
        break;
      case 'energy_modeling':
        setEnergyModelingCollapsed((prev) => !prev);
        break;
      case 'economics':
        setEconomicsCollapsed((prev) => !prev);
        break;
      default:
        break;
    }
  };

  const [greenBuildingCertificationsCheckbox, setGreenBuildingCertificationsCheckbox] = useState({
    leed: true,
    energy_star: true,
    well_building_standard: true,
  });

  const [certificationLevelInputValue, setCertificationLevelInputValue] = useState({
    leed: 'Platinum',
    energy_star: 'Certification',
    well_building_standard: 'Gold',
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCerfificationLevelChange = (event, key) => {
    if (key in greenBuildingCertificationsCheckbox) {
      setCertificationLevelInputValue({
        ...certificationLevelInputValue,
        [key]: event.target.value,
      });
    }
  };

  const handleGreenBuildingCertificationsChange = (event) => {
    setGreenBuildingCertificationsCheckbox({
      ...greenBuildingCertificationsCheckbox,
      [event.target.name]: event.target.checked,
    });
    if (!event.target.checked) {
      setCertificationLevelInputValue({
        ...certificationLevelInputValue,
        [event.target.name]: '',
      });
    }
  };

  const renderCertificationLevelInputs = useCallback(() => {
    /* eslint no-restricted-syntax: ["error"] */
    const inputs = [];
    for (const [key] of Object.entries(greenBuildingCertificationsCheckbox)) {
      if (greenBuildingCertificationsCheckbox[key]) {
        const inputField = (
          <Grid mb={2} key={key}>
            <InputField
              name={key}
              label={certificationLabel[key]}
              value={certificationLevelInputValue[key]}
              /* eslint no-restricted-globals: ["error"] */
              onChange={() => handleCerfificationLevelChange(event, key)}
              // handleBlur={handleBlur}
              fullWidth
            />
          </Grid>
        );
        inputs.push(inputField);
      }
    }
    return inputs;
  }, [greenBuildingCertificationsCheckbox, certificationLevelInputValue, handleCerfificationLevelChange]);

  const [technologiesCheckbox, setTechnologiesCheckbox] = useState({
    high_performance_insulation: true,
    energy_efficient_windows_and_doors: true,
    led_lighting: true,
    energy_management_system: true,
    solor_panels: true,
    wind_turbines: true,
    low_flow_fixtures: true,
    rainwater_harvesting: true,
    irrigation_management_system: true,
    construction_waste_reduction: true,
    recycling_facilities: true,
    air_quality_monitoring: true,
    non_toxic_materials_and_finishes: true,
    natural_lighting_optimization: true,
    building_automation_system: true,
    smart_thermostats: true,
    occupancy_sensors: true,
    energy_analytics_software: true,
  });

  const handleTechnologiesChange = (event) => {
    setTechnologiesCheckbox({
      ...technologiesCheckbox,
      [event.target.name]: event.target.checked,
    });
  };

  const [existingTradeRelationship, setExistingTradeRelationship] = useState('The building is projected to achieve a 35% reduction in energy consumption compared to a conventional office building of the same size.');

  const handleExistingTradeRelationshipChange = (event) => {
    setExistingTradeRelationship(event.target.value);
  }

  const [energyModelingInputValue, setEnergyModelingInputValue] = useState({
    projections_of_the_building_energy_performance: 'The building is projected to achieve a 35% reduction in energy consumption compared to a conventional office building of the same size.',
    renewable_energy_plans: "On-site renewable energy generation through solar panels and wind turbines is expected to supply 25% of the building's total energy needs.",
    resource_management_plans: 'Advanced energy management systems will be implemented to optimize resource usage, including automated controls for lighting, HVAC, and water systems.',
  });

  const handleEnergyModelingChange = (event, key) => {
    setEnergyModelingInputValue({
      ...energyModelingInputValue,
      [key]: event.target.value,
    });
  }

  const [economicsInputValue, setEconomicsInputValue] = useState({
    projected_energy_savings: '€410,000 annuallyf',
    annual_energy_cost_reduction: '35%',
    estimated_annual_savings: '€ 1,200,000',
    return_on_investment: '12% over 10 years',
    expected_payback_period: '12 years',
    internal_rate_of_return: '10%',
    hard_costs: '€16,000,000 (Construction, equipment, etc.)',
    soft_costs: '€4,000,000 (Design, permits, certifications, etc.)',
    owner_equity_contribution: '€ 6,000,000',
    subsidies: '€2,000,000 (EU Green Building Grant)',
    tax_incentives: '€500,000 (Federal energy tax credit)',
    lifecycle_cost_analysis: 'The total cost of ownership (TCO) over 20 years is estimated at €25,000,000, with significant savings expected from reduced energy and maintenance costs.',
    total_cost_of_ownership: '€ 23,000,000',
    maintenance_and_operating_costs: 'Projected at €100,000 annually, reflecting reduced operational costs due to energy efficiency measures.',
    comparison_with_conventional_building: 'The green building is expected to achieve 50% lower operational costs and 35% higher tenant retention rates compared to a conventional office building.',
    revenue_projections: 'The building is expected to generate €2,000,000 in annual rental income.',
    expected_rent_or_sales_price: '€30 per square meter per month for office space',
    occupancy_rate: '90% anticipated within the first year of operation',
  });

  const handleEconomicsChange = (event, key) => {
    setEconomicsInputValue({
      ...economicsInputValue,
      [key]: event.target.value,
    });
  }

  useEffect(() => {
    // fetch detail of deal
  }, []);

  return (
    <MKBox padding={5}>
      <MKBox display="flex" justifyContent="space-between" mb={3}>
        <MKBox>
          <MKTypography>Name of the project: EcoBuild</MKTypography>
          <MKTypography>Principal Amount: €15,000,000 </MKTypography>
          <MKTypography>Expected Date: 3/1/2025</MKTypography>
        </MKBox>
        <MKBox>
          <Select
            id="select_actions"
            value={actionValue || 'actions'}
            onChange={(e) => {
              setActionValue(e.target.value);
            }}
          >
            <MenuItem disabled value="actions">
              Actions
            </MenuItem>
            {actions.map((action) => (
              <MenuItem
                key={action.value}
                value={action.value}
                onClick={action.value === 'generate_term_sheet' ? () => window.open('https://www.ucfinn.com/EcoBuild_Term_Sheet.pdf') : null}
              >
                {action.label}
              </MenuItem>
            ))}
          </Select>
        </MKBox>
      </MKBox>

      <MKBox sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="basic tabs example">
          <Tab label="Details" value="details" />
          <Tab label="Documents" value="documents" />
          <Tab label="Collaterals" value="collaterals" />
          <Tab label="Covenants" value="covenants" />
          <Tab label="ESG" value="esg" />
          <Tab label="Carbon Accounting" value="carbon_accounting" />
          <Tab label="Structuring" value="structuring" />
          <Tab label="Analytics" value="analytics" />
        </Tabs>
      </MKBox>
      <CustomTabPanel value="details" tabValue={tabValue}>
        <MKBox style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '0.5rem' }}>
          <MKBox
            onClick={() => handleCollapseChange('business_info')}
            sx={{ cursor: 'pointer' }}
          >
            Business Information &gt;
          </MKBox>
          {businessInfoCollapsed && (
            <Collapse in={businessInfoCollapsed} style={{ padding: 5 }}>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Business / Organization Name:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.organization_name}</Grid>
              </Grid>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Name:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.name}</Grid>
              </Grid>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Title:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.title}</Grid>
              </Grid>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Email:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.email}</Grid>
              </Grid>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Business Website:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.business_website}</Grid>
              </Grid>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Region:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.region}</Grid>
              </Grid>
            </Collapse>
          )}
        </MKBox>
        <MKBox style={{ padding: '0.5rem', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '0.5rem' }}>
          <MKBox
            onClick={() => handleCollapseChange('project_info')}
            sx={{ cursor: 'pointer' }}
          >
            Project Info &gt;
          </MKBox>
          {projectInfoCollapsed && (
            <Collapse in={projectInfoCollapsed} style={{ padding: 5 }}>
              <MKBox>
                <MKTypography fontSize={16}>Type of Project</MKTypography>
                <RadioGroup style={{ marginLeft: 15 }} defaultValue="new_construction">
                  <FormControlLabel value="new_construction" control={<Radio />} label={<MKTypography fontSize={16}>New Construction</MKTypography>} />
                  <FormControlLabel value="renovation_retrofit" control={<Radio />} label={<MKTypography fontSize={16}>Renovation/Retrofit</MKTypography>} />
                </RadioGroup>
              </MKBox>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Project Scope/Description:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.project_description}</Grid>
              </Grid>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Estimated Project Cost:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.estimated_project_cost}</Grid>
              </Grid>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Requested Loan Amount:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.requested_loan_amount}</Grid>
              </Grid>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Start Date:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.start_date}</Grid>
              </Grid>
              <Grid container fontSize={16} marginBottom={1}>
                <Grid item sx={3} md={3} lg={3}>Completion Date:</Grid>
                <Grid item sx={9} md={9} lg={9}>{dealDetail.completion_date}</Grid>
              </Grid>
            </Collapse>
          )}
        </MKBox>
        <MKBox style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '0.5rem' }}>
          <MKBox
            onClick={() => handleCollapseChange('green_building_certifications')}
            sx={{ cursor: 'pointer' }}
          >
            Green Building Certifications &gt;
          </MKBox>
          {greenBuildingCertificationsCollapsed && (
            <Collapse in={greenBuildingCertificationsCollapsed} style={{ padding: 5 }}>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox checked={greenBuildingCertificationsCheckbox.leed} onChange={handleGreenBuildingCertificationsChange} name="leed" />
                    }
                    label="LEED (Leadership in Energy and Environmental Design)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={greenBuildingCertificationsCheckbox.breeam} onChange={handleGreenBuildingCertificationsChange} name="breeam" />
                    }
                    label="BREEAM (Building Research Establishment Environmental Assessment Method)"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={greenBuildingCertificationsCheckbox.energy_star} onChange={handleGreenBuildingCertificationsChange} name="energy_star" />
                    }
                    label="ENERGY STAR"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={greenBuildingCertificationsCheckbox.green_globes} onChange={handleGreenBuildingCertificationsChange} name="green_globes" />
                    }
                    label="Green Globes"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={greenBuildingCertificationsCheckbox.passive_house} onChange={handleGreenBuildingCertificationsChange} name="passive_house" />
                    }
                    label="Passive House"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={greenBuildingCertificationsCheckbox.well_building_standard} onChange={handleGreenBuildingCertificationsChange} name="well_building_standard" />
                    }
                    label="WELL Building Standard"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={greenBuildingCertificationsCheckbox.living_building_standard} onChange={handleGreenBuildingCertificationsChange} name="living_building_standard" />
                    }
                    label="Living Building Challenge"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox checked={greenBuildingCertificationsCheckbox.other} onChange={handleGreenBuildingCertificationsChange} name="other" />
                    }
                    label="Other (please specify):"
                  />
                  <InputField
                    disabled={!greenBuildingCertificationsCheckbox.other}
                    name="other"
                    label="Other"
                    value="Other certifications"
                    // handleChange={handleChange}
                    // handleBlur={handleBlur}
                    fullWidth
                  />
                </FormGroup>
              </FormControl>
            </Collapse>
          )}
        </MKBox>
        <MKBox style={{ padding: '0.5rem', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '0.5rem' }}>
          <MKBox
            onClick={() => handleCollapseChange('certification_level')}
            sx={{ cursor: 'pointer' }}
          >
            Certification level &gt;
          </MKBox>
          {certificationLevelCollapsed && (
            <Collapse in={certificationLevelCollapsed} style={{ padding: 5 }}>
              {renderCertificationLevelInputs()}
            </Collapse>
          )}
        </MKBox>
        <MKBox style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '0.5rem' }}>
          <MKBox
            onClick={() => handleCollapseChange('technologies')}
            sx={{ cursor: 'pointer' }}
          >
            Technologies &gt;
          </MKBox>
          {technologiesCollapsed && (
            <Collapse in={technologiesCollapsed} style={{ padding: 5 }}>
              <FormControl component="fieldset" variant="standard">
                <FormGroup>
                  <MKTypography fontSize={16}>Energy Efficiency Features:</MKTypography>
                  <MKBox marginLeft={3}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.high_performance_insulation} onChange={handleTechnologiesChange} name="high_performance_insulation" />
                      }
                      label="High-Performance Insulation"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.energy_efficient_windows_and_doors} onChange={handleTechnologiesChange} name="energy_efficient_windows_and_doors" />
                      }
                      label="Energy-Efficient Windows and Doors"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.led_lightning} onChange={handleTechnologiesChange} name="led_lightning" />
                      }
                      label="LED Lighting"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.energy_management_system} onChange={handleTechnologiesChange} name="energy_management_system" />
                      }
                      label="Energy Management System (EMS)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.other_energy_efficiency_features} onChange={handleTechnologiesChange} name="other_energy_efficiency_features" />
                      }
                      label="Other (please specify):"
                    />
                    <InputField
                      disabled={!technologiesCheckbox.other_energy_efficiency_features}
                      name="other_energy_efficiency_features"
                      label="Other"
                      value={technologiesCheckbox.other_energy_efficiency_features && 'Other Energy Efficiency Features'}
                      // handleChange={handleChange}
                      // handleBlur={handleBlur}
                      fullWidth
                    />
                  </MKBox>

                  <MKTypography marginTop={5} fontSize={16}>Renewable Energy Systems:</MKTypography>
                  <MKBox marginLeft={3}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.solar_panels} onChange={handleTechnologiesChange} name="solar_panels" />
                      }
                      label="Solar Panels"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.wind_turbines} onChange={handleTechnologiesChange} name="wind_turbines" />
                      }
                      label="Wind Turbines"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.geothermal_healing_cooling} onChange={handleTechnologiesChange} name="geothermal_healing_cooling" />
                      }
                      label="Geothermal Heating/Cooling"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.biomass} onChange={handleTechnologiesChange} name="biomass" />
                      }
                      label="Biomass"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.other_renewable_enery_systems} onChange={handleTechnologiesChange} name="other_renewable_enery_systems" />
                      }
                      label="Other (please specify):"
                    />
                    <InputField
                      disabled={!technologiesCheckbox.other_renewable_enery_systems}
                      name="other_renewable_enery_systems"
                      label="Other"
                      value={technologiesCheckbox.other_renewable_enery_systems && 'Other Renewable Energy Systems'}
                      // handleChange={handleChange}
                      // handleBlur={handleBlur}
                      fullWidth
                    />
                  </MKBox>

                  <MKTypography marginTop={5} fontSize={16}>Water Conservation Measures:</MKTypography>
                  <MKBox marginLeft={3}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.low_flow_fixtures} onChange={handleTechnologiesChange} name="low_flow_fixtures" />
                      }
                      label="Low-Flow Fixtures"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.rainwater_harvesting} onChange={handleTechnologiesChange} name="rainwater_harvesting" />
                      }
                      label="Rainwater Harvesting"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.greywater_recycling} onChange={handleTechnologiesChange} name="greywater_recycling" />
                      }
                      label="Greywater Recycling"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.irrigation_management_system} onChange={handleTechnologiesChange} name="irrigation_management_system" />
                      }
                      label="Irrigation Management System"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.other_water_conservation_measures} onChange={handleTechnologiesChange} name="other_water_conservation_measures" />
                      }
                      label="Other (please specify):"
                    />
                    <InputField
                      disabled={!technologiesCheckbox.other_water_conservation_measures}
                      name="other_water_conservation_measures"
                      label="Other"
                      value={technologiesCheckbox.other_water_conservation_measures && 'Other Water Conservation Measures'}
                      // handleChange={handleChange}
                      // handleBlur={handleBlur}
                      fullWidth
                    />
                  </MKBox>

                  <MKTypography marginTop={5} fontSize={16}>Waste Management Plan:</MKTypography>
                  <MKBox marginLeft={3}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.construction_waste_reduction} onChange={handleTechnologiesChange} name="construction_waste_reduction" />
                      }
                      label="Construction Waste Reduction"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.recycling_facilities} onChange={handleTechnologiesChange} name="recycling_facilities" />
                      }
                      label="Recycling Facilities"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.composting_systems} onChange={handleTechnologiesChange} name="composting_systems" />
                      }
                      label="Composting Systems"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.other_waste_management_plan} onChange={handleTechnologiesChange} name="other_waste_management_plan" />
                      }
                      label="Other (please specify):"
                    />
                    <InputField
                      disabled={!technologiesCheckbox.other_waste_management_plan}
                      name="other_waste_management_plan"
                      label="Other"
                      value={technologiesCheckbox.other_waste_management_plan && 'Other Water Management Plan'}
                      // handleChange={handleChange}
                      // handleBlur={handleBlur}
                      fullWidth
                    />
                  </MKBox>

                  <MKTypography marginTop={5} fontSize={16}>Indoor Environmental Quality:</MKTypography>
                  <MKBox marginLeft={3}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.air_quality_monitoring} onChange={handleTechnologiesChange} name="air_quality_monitoring" />
                      }
                      label="Air Quality Monitoring"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.non_toxic_materials_and_finishes} onChange={handleTechnologiesChange} name="non_toxic_materials_and_finishes" />
                      }
                      label="Non-Toxic Materials and Finishes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.natural_lighting_optimization} onChange={handleTechnologiesChange} name="natural_lighting_optimization" />
                      }
                      label="Natural Lighting Optimization"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.other_indoor_environmental_quality} onChange={handleTechnologiesChange} name="other_indoor_environmental_quality" />
                      }
                      label="Other (please specify):"
                    />
                    <InputField
                      disabled={!technologiesCheckbox.other_indoor_environmental_quality}
                      name="other_indoor_environmental_quality"
                      label="Other"
                      value={technologiesCheckbox.other_indoor_environmental_quality && 'Other Indoor Environmental Quality'}
                      // handleChange={handleChange}
                      // handleBlur={handleBlur}
                      fullWidth
                    />
                  </MKBox>

                  <MKTypography marginTop={5} fontSize={16}>Smart Building Technologies:</MKTypography>
                  <MKBox marginLeft={3}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.building_automation_system} onChange={handleTechnologiesChange} name="building_automation_system" />
                      }
                      label="Building Automation System (BAS)"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.smart_thermostats} onChange={handleTechnologiesChange} name="smart_thermostats" />
                      }
                      label="Smart Thermostats"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.occupancy_sensors} onChange={handleTechnologiesChange} name="occupancy_sensors" />
                      }
                      label="Occupancy Sensors"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.energy_analytics_software} onChange={handleTechnologiesChange} name="energy_analytics_software" />
                      }
                      label="Energy Analytics Software"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox checked={technologiesCheckbox.other_smart_building_technologies} onChange={handleTechnologiesChange} name="other_smart_building_technologies" />
                      }
                      label="Other (please specify):"
                    />
                    <InputField
                      disabled={!technologiesCheckbox.other_smart_building_technologies}
                      name="other_smart_building_technologies"
                      label="Other"
                      value={technologiesCheckbox.other_smart_building_technologies && 'Other Smart Building Technologies'}
                      // handleChange={handleChange}
                      // handleBlur={handleBlur}
                      fullWidth
                    />
                  </MKBox>
                </FormGroup>
              </FormControl>
            </Collapse>
          )}
        </MKBox>
        <MKBox style={{ padding: '0.5rem', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '0.5rem' }}>
          <MKBox
            onClick={() => handleCollapseChange('preferred_technology_suppliers')}
            sx={{ cursor: 'pointer' }}
          >
            Preferred technology suppliers (relevant for trade credit structuring) &gt;
          </MKBox>
          {preferredTechnologySuppliersCollapsed && (
            <Collapse in={preferredTechnologySuppliersCollapsed} style={{ padding: 5 }}>
              <MKTypography fontSize={16}>Please specify:</MKTypography>
              <Grid mb={2}>
                <InputField
                  label=""
                  value="Siemens AG"
                  fullWidth
                />
              </Grid>
              <Grid mb={2}>
                <InputField
                  label=""
                  value="Schneider Electric"
                  fullWidth
                />
              </Grid>
              <Grid mb={2}>
                <InputField
                  label=""
                  value="BASF SE"
                  fullWidth
                />
              </Grid>

              <MKTypography fontSize={16}>Existing trade relationships:</MKTypography>
              <Grid mb={2}>
                <InputField
                  label=""
                  value={existingTradeRelationship}
                  onChange={handleExistingTradeRelationshipChange}
                  type="textarea"
                  fullWidth
                />
              </Grid>
            </Collapse>
          )}
        </MKBox>
        <MKBox style={{ backgroundColor: '#f8f9fa', padding: '0.5rem', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '0.5rem' }}>
          <MKBox
            onClick={() => handleCollapseChange('energy_modeling')}
            sx={{ cursor: 'pointer' }}
          >
            Energy Modeling &gt;
          </MKBox>
          {energyModelingCollapsed && (
            <Collapse in={energyModelingCollapsed} style={{ padding: 5 }}>
              {/* eslint-disable react/no-unescaped-entities  */}
              <MKTypography fontSize={16}>Projections of the Building's Energy Performance:</MKTypography>
              <Grid mb={2}>
                <InputField
                  label=""
                  value={energyModelingInputValue.projections_of_the_building_energy_performance}
                  onChange={() => handleEnergyModelingChange(event, 'projections_of_the_building_energy_performance')}
                  type="textarea"
                  fullWidth
                />
              </Grid>

              <MKTypography fontSize={16}>Renewable Energy Plans:</MKTypography>
              <Grid mb={2}>
                <InputField
                  label=""
                  value={energyModelingInputValue.renewable_energy_plans}
                  onChange={() => handleEnergyModelingChange(event, 'renewable_energy_plans')}
                  type="textarea"
                  fullWidth
                />
              </Grid>
              <MKTypography fontSize={16}>Resource Management Plans</MKTypography>
              <Grid mb={2}>
                <InputField
                  label=""
                  value={energyModelingInputValue.resource_management_plans}
                  type="textarea"
                  onChange={() => handleEnergyModelingChange(event, 'resource_management_plans')}
                  fullWidth
                />
              </Grid>
            </Collapse>
          )}
        </MKBox>
        <MKBox style={{ padding: '0.5rem', borderRadius: '1rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', marginBottom: '0.5rem' }}>
          <MKBox
            onClick={() => handleCollapseChange('economics')}
            sx={{ cursor: 'pointer' }}
          >
            Economics &gt;
          </MKBox>
          {economicsCollapsed && (
            <Collapse in={economicsCollapsed} style={{ padding: 5 }}>
              <MKTypography fontSize={16}>Projected Energy Savings:</MKTypography>
              <Grid mb={2}>
                <InputField
                  label=""
                  value={economicsInputValue.projected_energy_savings}
                  onChange={() => handleEconomicsChange(event, 'projected_energy_savings')}
                  type="textarea"
                  fullWidth
                />
              </Grid>
              <MKTypography fontSize={16}>Annual Energy Cost Reduction (%):</MKTypography>
              <Grid mb={2}>
                <InputField
                  label=""
                  value={economicsInputValue.annual_energy_cost_reduction}
                  onChange={() => handleEconomicsChange(event, 'annual_energy_cost_reduction')}
                  type="textarea"
                  fullWidth
                />
              </Grid>
              <MKTypography fontSize={16}>Estimated Annual Savings (in €):</MKTypography>
              <Grid mb={2}>
                <InputField
                  label=""
                  value={economicsInputValue.estimated_annual_savings}
                  onChange={() => handleEconomicsChange(event, 'estimated_annual_savings')}
                  type="textarea"
                  fullWidth
                />
              </Grid>
              <MKTypography fontSize={16}>Return on Investment (ROI):</MKTypography>
              <Grid mb={2}>
                <InputField
                  label=""
                  value={economicsInputValue.return_on_investment}
                  onChange={() => handleEconomicsChange(event, 'return_on_investment')}
                  type="textarea"
                  fullWidth
                />
              </Grid>
              <MKTypography fontSize={16}>Expected Payback Period:</MKTypography>
              <Grid mb={2}>
                <InputField
                  label=""
                  value={economicsInputValue.expected_payback_period}
                  onChange={() => handleEconomicsChange(event, 'expected_payback_period')}
                  type="textarea"
                  fullWidth
                />
              </Grid>
              <MKTypography fontSize={16}>Internal Rate of Return (IRR):</MKTypography>
              <Grid mb={4}>
                <InputField
                  label=""
                  value={economicsInputValue.internal_rate_of_return}
                  onChange={() => handleEconomicsChange(event, 'internal_rate_of_return')}
                  type="textarea"
                  fullWidth
                />
              </Grid>
              <MKTypography fontSize={16}>Total Cost Breakdown:</MKTypography>
              <MKBox marginTop={1} marginLeft={3}>
                <MKTypography fontSize={16}>Hard Costs:</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.hard_costs}
                    onChange={() => handleEconomicsChange(event, 'hard_costs')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
                <MKTypography fontSize={16}>Soft Costs:</MKTypography>
                <Grid mb={4}>
                  <InputField
                    label=""
                    value={economicsInputValue.soft_costs}
                    onChange={() => handleEconomicsChange(event, 'soft_costs')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
              </MKBox>
              <MKTypography fontSize={16}>Funding Sources:</MKTypography>
              <MKBox marginTop={1} marginLeft={3}>
                <MKTypography fontSize={16}>Owner’s Equity Contribution:</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.owner_equity_contribution}
                    onChange={() => handleEconomicsChange(event, 'owner_equity_contribution')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
                <MKTypography fontSize={16}>Grants/Subsidies:</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.subsidies}
                    onChange={() => handleEconomicsChange(event, 'subsidies')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
                <MKTypography fontSize={16}>Tax Incentives:</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.tax_incentives}
                    onChange={() => handleEconomicsChange(event, 'tax_incentives')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
                <MKTypography fontSize={16}>Lifecycle Cost Analysis:</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.lifecycle_cost_analysis}
                    onChange={() => handleEconomicsChange(event, 'lifecycle_cost_analysis')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
                <MKTypography fontSize={16}>Total Cost of Ownership (TCO):</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.total_cost_of_ownership}
                    onChange={() => handleEconomicsChange(event, 'total_cost_of_ownership')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
                <MKTypography fontSize={16}>Maintenance and Operating Costs:</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.maintenance_and_operating_costs}
                    onChange={() => handleEconomicsChange(event, 'maintenance_and_operating_costs')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
                <MKTypography fontSize={16}>Comparison with Conventional Building</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.comparison_with_conventional_building}
                    onChange={() => handleEconomicsChange(event, 'comparison_with_conventional_building')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
                <MKTypography fontSize={16}>Revenue Projections:</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.revenue_projections}
                    onChange={() => handleEconomicsChange(event, 'revenue_projections')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
                <MKTypography fontSize={16}>Expected Rent or Sales Price:</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.expected_rent_or_sales_price}
                    onChange={() => handleEconomicsChange(event, 'expected_rent_or_sales_price')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
                <MKTypography fontSize={16}>Occupancy Rate (for rental projects):</MKTypography>
                <Grid mb={2}>
                  <InputField
                    label=""
                    value={economicsInputValue.occupancy_rate}
                    onChange={() => handleEconomicsChange(event, 'occupancy_rate')}
                    type="textarea"
                    fullWidth
                  />
                </Grid>
              </MKBox>
            </Collapse>
          )}
        </MKBox>
      </CustomTabPanel>
      <CustomTabPanel value="documents" tabValue={tabValue}>
        Coming Soon
      </CustomTabPanel>
      <CustomTabPanel value="collaterals" tabValue={tabValue}>
        Coming Soon
      </CustomTabPanel>
      <CustomTabPanel value="covenants" tabValue={tabValue}>
        Coming Soon
      </CustomTabPanel>
      <CustomTabPanel value="esg" tabValue={tabValue}>
        Coming Soon
      </CustomTabPanel>
      <CustomTabPanel value="carbon_accounting" tabValue={tabValue}>
        <DealCarbonAccounting />
      </CustomTabPanel>
      <CustomTabPanel value="structuring" tabValue={tabValue}>
        <DealStructuring />
      </CustomTabPanel>
      <CustomTabPanel value="analytics" tabValue={tabValue}>
        <DealAnalytics />
      </CustomTabPanel>
    </MKBox>
  );
};

DealDetailSection.propTypes = {

};

DealDetailSection.defaultProps = {

};

export default DealDetailSection;
