import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { getEvents } from 'api/events';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import ParameterListViewSection from 'sections/ParameterListView';

const EditEventTriggerForm = ({ eventTrigger, isEdit, onSave, ...props }) => {
  const initialValues = useMemo(() => ({
    event_trigger_name: eventTrigger?.event_trigger_name || '',
    event_trigger_type: eventTrigger?.event_trigger_type || '',
    output_signal_name: eventTrigger?.output_signal?.signal_name || '',
    event: eventTrigger?.event?.event_id || {},
  }), [eventTrigger]);

  const [eventId, setEventId] = useState('');
  const [eventTiggerType, setEventTiggerType] = useState('');
  const [searchParams] = useSearchParams();
  const { setAuth } = useAuth();
  const businessLogicId = searchParams.get('business_logic_id');
  const [events, setEvents] = useState([]);

  const fetchEventsFromApi = useCallback(() => {
    const params = {
      // business_logic: businessLogicId,
    };
    return businessLogicId && getEvents(params)
      .then(({ data }) => {
        setEvents(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [businessLogicId, setAuth]);

  useEffect(() => {
    fetchEventsFromApi();
  }, [fetchEventsFromApi]);

  useEffect(() => {
    setEventId(eventTrigger?.event?.event_id);
    setEventTiggerType(eventTrigger?.event_trigger_type);
  }, [eventTrigger]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty, setFieldValue }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <MKInput
                  name="event_trigger_name"
                  label="Event Trigger Name"
                  value={values.event_trigger_name || ''}
                  onChange={handleChange('event_trigger_name')}
                  onBlur={handleBlur('event_trigger_name')}
                  fullWidth
                />
              </Grid>

              <Grid container item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="event_trigger_type_label">Event Trigger Type</InputLabel>
                  <Select
                    labelId="event_trigger_type_label"
                    id="event_trigger_type_select"
                    value={eventTiggerType}
                    label="Event Trigger Type"
                    onChange={(e) => {
                      setFieldValue('event_trigger_type', e.target.value);
                      setEventTiggerType(e.target.value);
                    }}
                    onBlur={handleBlur('event_trigger_type')}
                  >
                    <MenuItem disabled value="">
                      Event Trigger Type
                    </MenuItem>
                    <MenuItem value="event">Event</MenuItem>
                    <MenuItem value="condition">Condition</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              <Grid container item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="event_label">Event</InputLabel>
                  <Select
                    labelId="event_label"
                    id="event_select"
                    value={eventId}
                    label="Event"
                    onChange={(e) => {
                      setFieldValue('event', e.target.value);
                      setEventId(e.target.value);
                    }}
                    onBlur={handleBlur('event')}
                  >
                    <MenuItem disabled value="">
                      Event
                    </MenuItem>
                    {events.map((event) => <MenuItem key={event.event_id} value={event.event_id}>{event.event_name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>

              <Grid container item xs={12}>
                <MKInput
                  name="output_signal_name"
                  label="Output Signal Name"
                  value={values.output_signal_name || ''}
                  onChange={handleChange('output_signal_name')}
                  onBlur={handleBlur('output_signal_name')}
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : 'Save'}
                </Button>
              </Grid>
            </Grid>
            {isEdit && (
              <Grid item xs={12}>
                <ParameterListViewSection />
              </Grid>
            )}
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditEventTriggerForm.propTypes = {
  eventTrigger: PropTypes.shape({
    event_trigger_name: PropTypes.string,
    event_trigger_type: PropTypes.string,
    output_signal: PropTypes.object,
    event: PropTypes.object,
  }),
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
};

EditEventTriggerForm.defaultProps = {
  eventTrigger: {},
  isEdit: false,
  onSave: () => {},
};

export default EditEventTriggerForm;
