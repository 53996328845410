import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { createBusinessLogic } from 'api/business_logics';
import EditBusinessLogicForm from 'sections/BusinessLogicEdit/EditBusinessLogicForm';

const BusinessLogicCreateSection = () => {
  const navigate = useNavigate();

  const createBusinessLogicToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      business_logic_name: values.business_logic_name,
    };

    return createBusinessLogic(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: '10px' }}>
          Create Business Logic
        </h4>
        <EditBusinessLogicForm
          onSave={createBusinessLogicToApi}
        />
      </Container>
    </MKBox>
  );
};

export default BusinessLogicCreateSection;
