import React, { useState } from 'react';
import qs from 'qs';
import pick from 'lodash/pick';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import Icon from '@mui/material/Icon';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import Button from 'components/Button';
import Image from 'components/Image';
import { useMenu } from 'contexts/menu';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Divider } from '@mui/material';
import SplashScreen from 'sections/CarbonFinanceSplashScreen';
import MobileMenu from './MobileMenu';

const MenuHeaderSection = ({ logo_url, use_button, button_label, button_only_home, button_navigate_to, background_color, display_searchbox, editable, onPressEdit, ...props }) => {
  const navigate = useNavigate();
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [dropdownAnchorEl, setDropdownAnchorEl] = useState(null);
  const [dropdownOptions, setDropdownOptions] = useState([]);
  const { menu } = useMenu();
  const { menu_node } = menu || {};
  const { menu_items } = menu_node || {};
  const location = useLocation();
  const queryParams = qs.parse((location.search || '').slice(1));

  const showButton = () => {
    if (button_only_home) {
      return use_button && location.pathname === '/';
    }
    return use_button;
  };

  const closeDropdown = () => {
    setDropdownAnchorEl(null); // Close the dropdown menu
  };

  const openDropdown = (menuItem, event) => {
    const { currentTarget } = event;
    if (dropdownAnchorEl) {
      closeDropdown(); // Close the dropdown menu if it's already open
      return;
    }
    setDropdownOptions(menuItem?.menu_items);
    setDropdownAnchorEl(currentTarget); // Set anchor element for the dropdown menu
  };

  const handleSubmenuClick = (path) => {
    navigate(path);
    closeDropdown(); // Close the dropdown menu after navigation
  };

  const getColor = (menuItem, primary, secondary) => {
    const subItems = menuItem?.menu_items;
    const pathTokens = menuItem?.path?.split('/');
    const locationPathTokens = location?.pathname?.split('/');
    if (pathTokens && pathTokens.length > 1 && locationPathTokens) {
      if (pathTokens[1] === locationPathTokens[1]) {
        return primary;
      }
      return secondary;
    }
    if (pathTokens === undefined && subItems.length > 0) {
      const subTokens = subItems[0].path.split('/');
      if (subTokens[1] === locationPathTokens[1]) {
        return primary;
      }
      return secondary;
    }
  };

  return (
    <MKBox
      px={{ xs: 0, md: 1 }}
      py={{ xs: 1, md: 2 }}
      bgColor={background_color}
      {...props}
    >
      <SplashScreen />
      <Container>
        <MKBox display="flex" justifyContent="space-between" alignItems="center">
          <MKBox display="flex" alignItems="center">
            <Image
              src={logo_url}
              height="2rem"
              mr={2}
            />
            {display_searchbox ? (
              <MKInput
                hiddenLabel
                placeholder="Search"
                variant="filled"
                size="small"
                fullWidth
                InputProps={{
                  sx: { borderRadius: '4px', px: 2 },
                  disableUnderline: true,
                }}
              />
            ) : null}
          </MKBox>
          <MKBox display={{ xs: 'none', lg: 'flex' }}>
            {
              showButton() ? (
                <MKBox display="flex">
                  <Button
                    onClick={() => { navigate(button_navigate_to || '/'); }}
                    variant="gradient"
                    color="info"
                    size="medium"
                    sx={{ borderRadius: '50', marginRight: 2 }}
                  >
                    { button_label || 'Button' }
                  </Button>
                  <Button
                    onClick={() => { navigate('/login?redirect=/deal_dashboard'); }}
                    variant="gradient"
                    color="info"
                    size="medium"
                    sx={{ borderRadius: '50' }}
                  >
                    LOS
                  </Button>
                </MKBox>
              ) : null
            }
          </MKBox>
          <MKBox display={{ xs: 'none', lg: 'flex' }}>
            <MKBox
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              flexWrap="wrap"
            >
              {(menu_items || []).map((menuItem, idx) => {
                const { label: menuItemLabel, type, path, disabled } = menuItem;
                if (type === 2) {
                  return !disabled ? (
                    <Button
                      key={`${idx}-${path}`}
                      to={`${path}?${qs.stringify(pick(queryParams, 'mode'))}`}
                      variant="text"
                      color={getColor(menuItem, 'dark', 'secondary')}
                      fontSize={16}
                      size="small"
                    >
                      {menuItemLabel}
                    </Button>
                  ) : null;
                }
                return !disabled ? (
                  <>
                    <Button
                      key={`${idx}-${path}`}
                      onClick={(event) => openDropdown(menuItem, event)}
                      variant="text"
                      color={getColor(menuItem, 'dark', 'secondary')}
                      fontSize={16}
                      size="small"
                    >
                      {menuItemLabel}
                    </Button>
                    <Menu
                      anchorEl={dropdownAnchorEl}
                      open={Boolean(dropdownAnchorEl)}
                      onClose={closeDropdown}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      {(dropdownOptions || []).map((subMenuItem, subIdx) => (
                        <React.Fragment key={`${subIdx}-${subMenuItem.path}`}>
                          <MenuItem
                            onClick={() => handleSubmenuClick(subMenuItem.path)}
                          >
                            {subMenuItem.label}
                          </MenuItem>
                          {/* Insert divider after each submenu item except for the last one */}
                          {subIdx !== menuItem.menu_items.length - 1 && <Divider />}
                        </React.Fragment>
                      ))}
                    </Menu>
                  </>
                ) : null;
              })}
            </MKBox>
          </MKBox>
          <MKBox
            display={{ xs: 'flex', lg: 'none' }}
            lineHeight={0}
            pl={1.5}
            sx={{ cursor: 'pointer' }}
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          >
            <Icon fontSize="default" color="dark">
              {mobileMenuOpen ? 'close' : 'menu'}
            </Icon>
          </MKBox>
        </MKBox>
        <MKBox
          shadow="lg"
          borderRadius="xl"
          display={{ xs: 'flex', lg: 'none' }}
          px={2}
        >
          <MobileMenu
            menuItems={menu_items}
            open={mobileMenuOpen}
            setOpen={setMobileMenuOpen}
            extraButton={showButton()}
            buttonLabel={button_label}
            buttonNavigateTo={button_navigate_to}
            openDropdown={openDropdown}
            closeDropdown={closeDropdown}
            dropdownAnchorEl={dropdownAnchorEl}
            dropdownOptions={dropdownOptions}
            handleSubmenuClick={handleSubmenuClick}
            getColor={getColor}
          />
        </MKBox>
      </Container>
    </MKBox>
  );
};

MenuHeaderSection.propTypes = {
  logo_url: PropTypes.string,
  background_color: PropTypes.string,
  display_searchbox: PropTypes.bool,
  use_button: PropTypes.bool,
  button_label: PropTypes.string,
  button_navigate_to: PropTypes.string,
  button_only_home: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default MenuHeaderSection;
