import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import EditDealForm, { dealTypes } from 'sections/DealEdit/EditDealForm';
import { random } from 'chroma-js';

const DealCreateSection = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const dealId = searchParams.get('deal_id');

  const addDealToList = useCallback((values, { setFieldError }) => {
    const payload = {
      deal_id: Math.random(),
      deal_type: dealTypes.find((type) => type.value === values.deal_type)?.label,
      organization_name: values.organization_name,
      name: values.name,
      title: values.title,
      email: values.email,
      business_website: values.business_website,
      region: values.region,
      financing_amount: values.financing_amount,
      brief_description: values.brief_description,
      project_rationale: values.project_rationale,
      status: 'Lead',
    };
    return navigate('deal_dashboard', { state: { new_deal: payload } });
  }, [navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: '10px' }}>
          Create Deal
        </h4>
        <EditDealForm
          onSave={addDealToList}
        />
      </Container>
    </MKBox>
  );
};

export default DealCreateSection;
