import PropTypes from 'prop-types';
import Banner from 'components/Banner';

const BannerSection = ({ src, title, subtitle, title_props, subtitle_props, justify_content, align_items, height, background_size, background_position, editable, onPressEdit, ...props }) => {
  return (
    <Banner
      src={src}
      title={title}
      subtitle={subtitle}
      titleProps={title_props}
      subtitleProps={subtitle_props}
      justifyContent={justify_content}
      alignItems={align_items}
      height={height}
      backgroundSize={background_size}
      backgroundPosition={background_position}
      editable
      onPressEdit
      {...props}
    />
  );
};

BannerSection.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  title_props: PropTypes.object,
  subtitle: PropTypes.string,
  subtitle_props: PropTypes.object,
  justify_content: PropTypes.string,
  align_items: PropTypes.string,
  height: PropTypes.string,
  background_size: PropTypes.string,
  background_position: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

BannerSection.defaultProps = {
  src: null,
  title: null,
  subtitle: null,
};

export default BannerSection;
