import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';

const SplashScreen = () => {
  const [open, setOpen] = useState(true);
  const [accepted, setAccepted] = useState(false);

  useEffect(() => {
    const disclaimerAccepted = localStorage.getItem('disclaimerAccepted');
    if (disclaimerAccepted) {
      setAccepted(true);
      setOpen(false);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem('disclaimerAccepted', true);
    setAccepted(true);
    setOpen(false);
  };

  return (
    <Dialog open={open} maxWidth="lg">
      <MKBox px={4} py={4} sx={{ backgroundColor: '#358DED' }}>
        <MKTypography variant="body1" fontSize="16px" fontWeight="bold" color="white">
          Disclaimer:
        </MKTypography>
        <MKTypography variant="body2" fontSize="16px" color="white" mt="8px">
          The products and financing services referred to on this website are offered only in jurisdictions outside Hong Kong where and when they may be lawfully offered by UCFINN.
        </MKTypography>
        <MKTypography variant="body2" fontSize="16px" color="white" mt="8px">
          The information provided on this website is not intended for marketing, distribution to, or use by, any person in Hong Kong or any jurisdiction where such marketing, distribution or use would be contrary to law or regulation.
        </MKTypography>
        <MKTypography variant="body2" fontSize="12px" color="white" mt="16px">
          * By clicking &#39;accept&#39; you acknowledge that you have read and understood the disclaimer
        </MKTypography>
        <MKBox display="flex" flexDirection="column" alignItems="center" mt="16px" justifyContent="space-between">
          <Button variant="contained" color="info" onClick={handleAccept} sx={{ mt: '8px' }}>
            <MKTypography variant="button" fontWeight="bold" color="#358DED">
              Accept
            </MKTypography>
          </Button>
        </MKBox>
      </MKBox>
    </Dialog>
  );
};

export default SplashScreen;
