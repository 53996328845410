import { datetimeFormatter } from 'utils/datetime';

export const dealsTableColumns = [
  { field: 'name', label: 'Name' },
  { field: 'deal_type', label: 'Product' },
  { field: 'financing_amount', label: 'Principal' },
  { field: 'contract_date', label: 'Contract Date', formatter: datetimeFormatter },
  { field: 'maturity_date', label: 'Maturity Date', formatter: datetimeFormatter },
  { field: 'status', label: 'Status' },
];
