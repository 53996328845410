import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createApp } from 'api/apps';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import EditAppForm from 'pages/AppSummary/EditAppForm';

const AppCreate = () => {
  const [searchParams] = useSearchParams();
  const solutionId = searchParams.get('solution_id');
  const navigate = useNavigate();

  const createAppToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      solution: solutionId,
      display_name: values.display_name,
      base_url: values.base_url,
      major_version: values.major_version,
      minor_version: values.minor_version,
      type: parseInt(values.type, 10),
    };

    return createApp(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        setFieldError('form', err.message);
      });
  }, [solutionId, navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: 10 }}>
          Create App
        </h4>
        <EditAppForm
          onSave={createAppToApi}
        />
      </Container>
    </MKBox>
  );
};

export default AppCreate;
