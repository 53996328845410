import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material';
import MKTypography from 'components/MaterialKit/MKTypography';

const createData1 = (benefit_type, description, impact_in_euro, impact_in_ccu, environmental_impact, environmental_impact_in_ccu) => {
  return { benefit_type, description, impact_in_euro, impact_in_ccu, environmental_impact, environmental_impact_in_ccu };
};

const dataRows1 = [
  createData1('Interest Rate Savings', 'Reduced rates due to ESG certification', '€100,000 annually', '1,250 CCUs annually', '-', '-'),
  createData1('Increased Asset Value', 'Asset value increase from flexibility', '€2 million', '25,000 CCUs', '-', '-'),
  createData1('Risk Reduction Savings', 'Savings from risk management', '€1 million', '12,500 CCUs', '-', '-'),
  createData1('Energy Efficiency Savings', 'Annual energy cost reductions', '€8.2 million (over 20 years)', '102,500 CCUs (over 20 years)', 'Estimated annual energy savings of 1,400 MWh', '35,000 CCUs annually'),
  createData1('Total (over 20 years)', 'Sum of monetary and environmental benefits', '€13.2 million', '165,000 CCUs', '6,524 tonnes of CO2 emissions avoided.', '700,000 CCUs'),
];

const createData2 = (field, description, sample_data, ccu_related_outcomes) => {
  return { field, description, sample_data, ccu_related_outcomes };
};

const dataRows2 = [
  createData2('Credit Score', "Default probability based on borrower's history.", '780 (Low risk)', '-'),
  createData2('Loan-to-Value (LTV)', 'Ratio of loan amount to the value of the property.', '60%', '-'),
  createData2('Debt Service Coverage Ratio (DSCR)', 'Measures cash flow available to pay current debt obligations.', '1.5x', '-'),
  createData2('ESG Score', 'Rating based on environmental, social, and governance factors.', '85 out of 100', '-'),
  createData2('Risk Assessment', 'Comprehensive risk score reflecting all risk factors.', '4.5 (on a scale of 1-10, 10 being highest risk)', '-'),
  createData2('Sensitivity Analysis', 'Examines how different values of an independent variable affect a particular dependent variable.', 'EBITDA sensitivity to interest rate changes +/- 2%', '-'),
  createData2('Comparative Analysis', "Compares this loan's metrics against industry or similar projects.", 'Better by 15% in DSCR compared to industry average', '-'),
  createData2('Est. Interest Rate Spread', 'Additional interest rate over the base rate due to risk factors.', '+350 basis points', '+300 basis points'),
  createData2('Expected Yield on Debt (Annual)', 'The expected annual yield on the debt financing.', '6.5% annually', '6.0% annually'),
  createData2('Green Yield (CCU)', 'Yield calculated in terms of carbon credit units saved from the project.', '35,000 CCUs annually', '35,000 CCUs annually'),
  createData2('Value at Risk (VaR)', 'Estimates the maximum loss expected over a given time period at a given confidence interval.', '€600,000 at 95% confidence over one year', '6,250 CCUs'),
  createData2('Expected Shortfall (ES)', 'Measures the expected loss on days when there is a VaR breach, providing a sense of extreme tail risk.', '€750,000 beyond VaR level', '9,375 CCUs'),
  createData2('Return on Investment (ROI)', 'Calculates the gain or loss generated on an investment relative to the amount of money invested, focused on equity.', '20% over the project lifecycle', '20.05% over the project lifecycle'),
  createData2('Net Present Value (NPV)', 'The difference between the present value of cash inflows and outflows over a period of time from an equity perspective.', '€1.2 million positive', '15,000 CCUs'),
  createData2('Probability of Default (Annual)', 'Estimation of the likelihood that the borrower will be unable to make necessary payments within a year.', '0.26% annually', '0.15% annually'),
  createData2('Probability of Default (Lifetime)', 'Estimation of the likelihood that the borrower will be unable to make necessary payments over the life of the loan.', '5% over the life of the loan', '3% over the life of the loan'),
  createData2('Break-even Analysis', "The point at which total costs and total revenue are equal, analyzing the entire project's financial structure.", 'Achieved in year 6', '-'),
  createData2('Capital Adequacy Ratio', "Measures a bank's capital in relation to its risk.", '18% (well above regulatory requirements)', '15%'),
];

const DealAnalytics = ({ ...props }) => {
  return (
    <MKBox>
      <MKTypography color="black" marginBottom="1rem">1. Impact Summary</MKTypography>
      <TableContainer style={{ marginBottom: '5rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: 'table-header-group', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell align="center">Benefit Type</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Impact in Euros</TableCell>
              <TableCell align="center">Impact in CCUs</TableCell>
              <TableCell align="center">Environmental Impact</TableCell>
              <TableCell align="center">Environmental Impact in CCUs</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows1.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>{row.benefit_type}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.impact_in_euro}</TableCell>
                <TableCell>{row.impact_in_ccu}</TableCell>
                <TableCell>{row.environmental_impact}</TableCell>
                <TableCell>{row.environmental_impact_in_ccu}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <MKTypography color="black" marginBottom="1rem">2. Financial Analysis</MKTypography>
      <TableContainer style={{ marginBottom: '5rem' }}>
        <Table sx={{ minWidth: 650 }}>
          <TableHead sx={{ display: 'table-header-group', fontWeight: 'bold' }}>
            <TableRow>
              <TableCell align="center">Field</TableCell>
              <TableCell align="center">Description</TableCell>
              <TableCell align="center">Sample Data</TableCell>
              <TableCell align="center">CCU-Related Outcomes</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataRows2.map((row) => (
              <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell component="th" scope="row" style={{ fontWeight: 'bold' }}>{row.field}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell>{row.sample_data}</TableCell>
                <TableCell>{row.ccu_related_outcomes}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </MKBox>
  );
};

DealAnalytics.propTypes = {

};

DealAnalytics.defaultProps = {
};

export default DealAnalytics;
