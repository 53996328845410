import PropTypes from 'prop-types';
import MKTypography from 'components/MaterialKit/MKTypography';

const TextSection = ({ text, variant, align, color, font_size, font_weight, is_rich_text, editable, onPressEdit, is_unix_timestamp, ...props }) => {
  const formattedFontSize = Number.isNaN(Number(font_size)) ? font_size : Number(font_size);

  const formatUnixTimestamp = (unixTimestamp) => {
  // Create a Date object by multiplying the Unix timestamp by 1000
    const date = Date(parseInt(unixTimestamp, 10) / 1000);
    return date.toLocaleString();
  };

  return (
    <MKTypography
      variant={variant}
      textAlign={align}
      color={color}
      fontSize={formattedFontSize}
      fontWeight={font_weight}
      {...(is_rich_text ? {
        dangerouslySetInnerHTML: {
          __html: is_unix_timestamp ? (formatUnixTimestamp(text) || '') : (text || ''),
        },
      } : {
        children: is_unix_timestamp ? (formatUnixTimestamp(text) || '') : (text || '') || '',
      })}
      {...props}
    />
  );
};

TextSection.propTypes = {
  text: PropTypes.string,
  variant: PropTypes.string,
  align: PropTypes.string,
  color: PropTypes.string,
  font_size: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  font_weight: PropTypes.string,
  is_rich_text: PropTypes.bool,
  is_unix_timestamp: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

export default TextSection;
