import { useCallback, useEffect, useMemo, useState } from 'react';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getEventTriggers, deleteEventTrigger } from 'api/event_triggers';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DataTable from 'components/DataTable';

const EventTriggerListViewSection = () => {
  const eventTriggerTableColumns = useMemo(() => [
    {
      field: 'event_trigger_id',
      label: 'Event ID',
    },
    { field: 'event_trigger_name' },
  ], []);

  const [eventTriggers, setEventTriggers] = useState([]);
  const { setAuth } = useAuth();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const businessLogicId = searchParams.get('business_logic_id');

  const fetchEventTriggersFromApi = useCallback(() => {
    const params = {
      business_logic: businessLogicId,
    };
    return businessLogicId && getEventTriggers(params)
      .then(({ data }) => {
        setEventTriggers(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [businessLogicId, setAuth]);

  const onPressAdd = useCallback(() => {
    navigate(`/create_event_trigger?business_logic_id=${businessLogicId}`);
  }, [navigate, businessLogicId]);

  const onPressEdit = useCallback((eventTriggerId) => {
    navigate(`/event_trigger?event_trigger_id=${eventTriggerId}&business_logic_id=${businessLogicId}&edit=true`);
  }, [businessLogicId, navigate]);

  const onPressDelete = useCallback((eventTriggerId) => {
    return deleteEventTrigger(eventTriggerId)
      .then(() => {
        return fetchEventTriggersFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchEventTriggersFromApi, setAuth]);

  useEffect(() => {
    fetchEventTriggersFromApi();
  }, [fetchEventTriggersFromApi]);

  return (
    <MKBox my={2}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h5">Event Triggers</MKTypography>
      </MKBox>
      <DataTable
        data={eventTriggers}
        columns={eventTriggerTableColumns}
        idField="event_trigger_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        selectable
      />
    </MKBox>
  );
};

export default EventTriggerListViewSection;
