import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import EditSignalForm from 'sections/SignalEdit/EditSignalForm';
import { createHandlerSignal } from 'api/handler_signals';
import { createSignal } from 'api/signals';

const SignalCreateSection = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const businessLogicId = searchParams.get('business_logic_id');
  const handlerId = searchParams.get('handler_id');
  const isDecisionHandler = searchParams.get('is_decision_handler');

  const createHandlerToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      signal_name: values.signal_name,
      sequence: values.sequence ? values.sequence : 0,
      handler: handlerId,
      business_logic: businessLogicId,
      signal_type: 'out',
      decision_type: values.decision_type,
    };

    return createSignal(payload)
      .then((obj) => {
        if (obj && obj.status === 201) {
          const handlerSignalBody = {
            signal_type: 'out',
            handler: handlerId,
            signal: obj.data.signal_id,
          };
          return Promise.resolve(createHandlerSignal(handlerSignalBody)).then((promise) => {
            if (promise.status === 201) {
              navigate(-1);
            } else {
              setFieldError('form', 'Fail to create handler_signals');
            }
          });
        }
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [businessLogicId, handlerId, navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: '10px' }}>
          Create Output Signal
        </h4>
        <EditSignalForm
          onSave={createHandlerToApi}
          isDecisionHandler={isDecisionHandler === 'true'}
        />
      </Container>
    </MKBox>
  );
};

export default SignalCreateSection;
