import PropTypes from 'prop-types';
import Carousel from 'components/Carousel';
import { useState, useEffect, useCallback, useMemo } from 'react';
import { getPublicSections } from 'api/sections';
import { fetchSectionProps, renderSectionComponent } from 'utils/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import MKBox from 'components/MaterialKit/MKBox';

const CarouselSection = ({ items, direction, item_props, timer_ms, hide_indicators, editable, onPressEdit, ...props }) => {
  const [subSections, setSubSections] = useState([]);
  const [subSectionsProps, setSubSectionsProps] = useState({});
  const { auth, setAuth } = useAuth();
  const timer = Number(timer_ms) || 3000;

  const fetchSectionsFromApi = useCallback((sectionIds) => {
    const sectionParams = {
      'section_id[in]': sectionIds.join(','),
      '$expand': 'section_definition/collection_definition/attributes',
    };
    return getPublicSections(sectionParams)
      .then(({ data }) => {
        setSubSections(data);
      });
  }, []);

  useEffect(() => {
    const sectionIds = (items || []).reduce((ids, item) => {
      const sections = item.sections || (item.section ? [item.section] : []);
      if (sections) {
        return [...ids, ...sections];
      }
      return ids;
    }, []);
    if (sectionIds.length > 0) {
      fetchSectionsFromApi(sectionIds, !auth.user)
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    }
  }, [items, fetchSectionsFromApi, auth.user, setAuth]);

  useEffect(() => {
    (subSections || []).forEach((subSection) => {
      const { section_id } = subSection;
      return fetchSectionProps(subSection, !auth.user)
        .then((subSectionProps) => {
          setSubSectionsProps((oriSubSectionsProps) => {
            const updatedSubSectionsProps = { ...oriSubSectionsProps };
            updatedSubSectionsProps[section_id] = subSectionProps;
            return updatedSubSectionsProps;
          });
        })
        .catch((err) => {
          handleErrorResponse(err, setAuth);
        });
    });
  }, [subSections, auth.user, setAuth]);

  const renderableItems = useMemo(() => {
    return (items || []).map((item, i) => {
      const { sections, section, ...extraProps } = item;
      const sectionIds = sections || (section ? [section] : []);
      return (sectionIds || []).map((sectionId, j) => {
        const subSection = subSections.find((s) => s.section_id === sectionId);
        const sectionComponent = renderSectionComponent(subSection, subSectionsProps[sectionId], extraProps.editable, extraProps.onPressEdit);
        return subSection ? sectionComponent : null;
      });
    });
  }, [items, subSections, subSectionsProps]);

  const settings = {
    autoplay: true,
    dots: !hide_indicators,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    easing: 'cubic-bezier',
    pauseOnHover: true,
    autoplaySpeed: timer_ms || 3000,
    infinite: true,
  };

  return (
    <MKBox sx={{ ...props }}>
      { renderableItems.length > 0 && <Carousel items={renderableItems} settings={settings} itemProps={item_props} /> }
    </MKBox>
  );
};

CarouselSection.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({}),
  ),
  direction: PropTypes.oneOf(['row', 'column']),
  item_props: PropTypes.object,
  timer_ms: PropTypes.string,
  hide_indicators: PropTypes.bool,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

CarouselSection.defaultProps = {
  items: [],
  hide_indicators: false,
  item_props: {},
  timer_ms: null,
};

export default CarouselSection;
