import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';

const EditMenuForm = ({ menu, onSave, onCancel, ...props }) => {
  const initialValues = useMemo(() => ({
    display_name: menu?.display_name || '',
    primary_color: menu?.primary_color || '',
    secondary_color: menu?.secondary_color || '',
    font_color: menu?.font_color || '',
    container_props: menu?.container_props || '{}',
    header_label: menu?.menu_node?.label || '',
    header_label_props: menu?.menu_node?.label_props || '{}',
    header_img_url: menu?.menu_node?.img_url || '',
    header_img_props: menu?.menu_node?.img_props || '{}',
    header_container_props: menu?.menu_node?.container_props || '{}',
    item_container_props: menu?.item_container_props || '{}',
    active_item_container_props: menu?.active_item_container_props || '{}',
    item_img_props: menu?.item_img_props || '{}',
    active_item_img_props: menu?.active_item_img_props || '{}',
    item_label_props: menu?.item_label_props || '{}',
    active_item_label_props: menu?.active_item_label_props || '{}',
  }), [menu]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <MKInput
                  name="display_name"
                  label="Display Name"
                  value={values.display_name || ''}
                  onChange={handleChange('display_name')}
                  onBlur={handleBlur('display_name')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="header_label"
                  label="Header Label"
                  value={values.header_label || ''}
                  onChange={handleChange('header_label')}
                  onBlur={handleBlur('header_label')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="header_label_props"
                  label="Header Label Props"
                  value={values.header_label_props || '{}'}
                  onChange={handleChange('header_label_props')}
                  onBlur={handleBlur('header_label_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="header_img_url"
                  label="Header Logo URL"
                  value={values.header_img_url || ''}
                  onChange={handleChange('header_img_url')}
                  onBlur={handleBlur('header_img_url')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="header_img_props"
                  label="Header Logo Props"
                  value={values.header_img_props || '{}'}
                  onChange={handleChange('header_img_props')}
                  onBlur={handleBlur('header_img_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="header_container_props"
                  label="Header Container Props"
                  value={values.header_container_props || '{}'}
                  onChange={handleChange('header_container_props')}
                  onBlur={handleBlur('header_container_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="primary_color"
                  label="Primary color"
                  value={values.primary_color || ''}
                  onChange={handleChange('primary_color')}
                  onBlur={handleBlur('primary_color')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="secondary_color"
                  label="Secondary color"
                  value={values.secondary_color || ''}
                  onChange={handleChange('secondary_color')}
                  onBlur={handleBlur('secondary_color')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="font_color"
                  label="Font color"
                  value={values.font_color || ''}
                  onChange={handleChange('font_color')}
                  onBlur={handleBlur('font_color')}
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="container_props"
                  label="Container Props"
                  value={values.container_props || '{}'}
                  onChange={handleChange('container_props')}
                  onBlur={handleBlur('container_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="item_container_props"
                  label="Item Container Props"
                  value={values.item_container_props || '{}'}
                  onChange={handleChange('item_container_props')}
                  onBlur={handleBlur('item_container_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="active_item_container_props"
                  label="Active Item Container Props"
                  value={values.active_item_container_props || '{}'}
                  onChange={handleChange('active_item_container_props')}
                  onBlur={handleBlur('active_item_container_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="item_img_props"
                  label="Item Image Props"
                  value={values.item_img_props || '{}'}
                  onChange={handleChange('item_img_props')}
                  onBlur={handleBlur('item_img_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="active_item_img_props"
                  label="Active Item Image Props"
                  value={values.active_item_img_props || '{}'}
                  onChange={handleChange('active_item_img_props')}
                  onBlur={handleBlur('active_item_img_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="item_label_props"
                  label="Item Label Props"
                  value={values.item_label_props || '{}'}
                  onChange={handleChange('item_label_props')}
                  onBlur={handleBlur('item_label_props')}
                  multiline
                  fullWidth
                />
              </Grid>
              <Grid container item xs={12}>
                <MKInput
                  name="active_item_label_props"
                  label="Active Item Label Props"
                  value={values.active_item_label_props || '{}'}
                  onChange={handleChange('active_item_label_props')}
                  onBlur={handleBlur('active_item_label_props')}
                  multiline
                  fullWidth
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <MKBox display="flex">
                  <MKBox display="flex" flex={1}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      Cancel
                    </Button>
                  </MKBox>
                  <MKBox display="flex" flex={1} ml={2}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      disabled={isSubmitting || !dirty}
                      fullWidth
                    >
                      {isSubmitting ? (
                        <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                      ) : 'Save'}
                    </Button>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditMenuForm.propTypes = {
  menu: PropTypes.shape({
    display_name: PropTypes.string,
    primary_color: PropTypes.string,
    secondary_color: PropTypes.string,
    font_color: PropTypes.string,
    container_props: PropTypes.string,
    item_container_props: PropTypes.string,
    active_item_container_props: PropTypes.string,
    item_img_props: PropTypes.string,
    active_item_img_props: PropTypes.string,
    item_label_props: PropTypes.string,
    active_item_label_props: PropTypes.string,
    menu_node: PropTypes.shape({
      label: PropTypes.string,
      type: PropTypes.number,
      path: PropTypes.string,
      img_url: PropTypes.string,
      container_props: PropTypes.string,
      img_props: PropTypes.string,
      label_props: PropTypes.string,
    }),
  }),
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditMenuForm.defaultProps = {
  menu: {},
  onSave: () => {},
  onCancel: () => {},
};

export default EditMenuForm;
