import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import DataTable from 'components/DataTable';
import { getApps, deleteApp } from 'api/apps';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';

const AppListViewSection = () => {
  const [apps, setApps] = useState([]);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const solutionId = searchParams.get('solution_id');

  const fetchAppsFromApi = useCallback(() => {
    if (!solutionId) {
      return Promise.resolve();
    }
    const params = {
      solution: solutionId,
    };
    return getApps(params)
      .then(({ data }) => {
        setApps(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [solutionId, setAuth]);

  const onPressAdd = useCallback(() => {
    if (!solutionId) {
      return Promise.resolve();
    }
    navigate(`/createapp?solution_id=${solutionId}`);
  }, [solutionId, navigate]);

  const onPressEdit = useCallback((appId) => {
    navigate(`/app/${appId}`);
  }, [navigate]);

  const onPressDelete = useCallback((appId) => {
    return deleteApp(appId)
      .then(() => {
        return fetchAppsFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchAppsFromApi, setAuth]);

  useEffect(() => {
    fetchAppsFromApi();
  }, [fetchAppsFromApi]);

  const appTableColumns = useMemo(() => [
    {
      field: 'app_id',
      label: 'App ID',
    },
    { field: 'display_name' },
    { field: 'major_version' },
    { field: 'minor_version' },
    { field: 'base_url' },
    { field: 'type' },
  ], []);

  return (
    <MKBox my={2}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h5">Apps</MKTypography>
      </MKBox>
      <DataTable
        data={apps}
        columns={appTableColumns}
        idField="app_id"
        onPressAdd={onPressAdd}
        onPressEdit={onPressEdit}
        onPressDelete={onPressDelete}
        paginationDisabled
        selectable
      />
    </MKBox>
  );
};

export default AppListViewSection;
