import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import DataTable from 'components/DataTable';
import { getSections, deleteSection, deleteDynamicTableRow } from 'api/sections';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';

const SectionList = () => {
  const [sections, setSections] = useState([]);
  const navigate = useNavigate();
  const { appId, pageId } = useParams();
  const { setAuth } = useAuth();

  const fetchSectionsFromApi = useCallback(() => {
    if (!pageId) {
      return Promise.resolve();
    }
    const sectionParams = {
      page: pageId,
      $orderBy: 'sequence',
      $expand: 'section_definition/collection_definition',
    };
    return getSections(sectionParams)
      .then(({ data }) => {
        setSections(data);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [pageId, setAuth]);

  const deleteSectionFromApi = useCallback((section) => {
    const { section_id, section_definition, dyn_t } = section;
    const collectionDefinitionId = section_definition?.collection_definition?.collection_definition_id;
    return deleteSection(section_id)
      .then(() => deleteDynamicTableRow(collectionDefinitionId, dyn_t))
      .then(() => fetchSectionsFromApi())
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchSectionsFromApi, setAuth]);

  const onClickAdd = useCallback(() => {
    navigate(`/app/${appId}/page/${pageId}/section`);
  }, [appId, pageId, navigate]);

  const onClickEdit = useCallback((sectionId) => {
    navigate(`/app/${appId}/page/${pageId}/section/${sectionId}`);
  }, [appId, pageId, navigate]);

  const onClickDelete = useCallback((sectionId) => {
    const section = sections.find(({ section_id }) => section_id === sectionId);
    return deleteSectionFromApi(section);
  }, [sections, deleteSectionFromApi]);

  useEffect(() => {
    fetchSectionsFromApi();
  }, [fetchSectionsFromApi]);

  const sectionTableColumns = useMemo(() => [
    { field: 'sequence' },
    {
      field: 'section_definition.collection_definition.name',
      label: 'Collection Definition',
    },
    {
      field: 'is_viewport',
      formatter: (v) => (v ? 'Yes' : 'No'),
    },
  ], []);

  return (
    <MKBox mt={4}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h6">Sections</MKTypography>
      </MKBox>
      <DataTable
        data={sections}
        columns={sectionTableColumns}
        idField="section_id"
        onPressAdd={onClickAdd}
        onPressEdit={onClickEdit}
        onPressDelete={onClickDelete}
        paginationDisabled
        selectable
      />
    </MKBox>
  );
};

export default SectionList;
