import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
import MKBox from 'components/MaterialKit/MKBox';
import MKBadge from 'components/MaterialKit/MKBadge';
import MKTypography from 'components/MaterialKit/MKTypography';
import DataTable from 'components/DataTable';
import { getMenuItemDependencies, deleteMenuItem } from 'api/menus';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';

const MenuItemList = ({ menuNodeId }) => {
  const [menuItemDependencies, setMenuItemDependencies] = useState([]);
  const { appId, menuId, menuItemDependencyId } = useParams();
  const navigate = useNavigate();
  const { setAuth } = useAuth();

  const fetchMenuItemDependenciesFromApi = useCallback(() => {
    if (!menuNodeId) {
      return Promise.resolve();
    }
    const menuItemDependencyParams = {
      menu_node: menuNodeId,
      $orderBy: 'sequence',
      $expand: 'menu_item',
    };
    return getMenuItemDependencies(menuItemDependencyParams)
      .then(({ data }) => {
        setMenuItemDependencies(data);
      });
  }, [menuNodeId]);

  const onClickAdd = useCallback(() => {
    navigate(`/app/${appId}/menu/${menuId}/menu-item-dependency${menuItemDependencyId ? `/${menuItemDependencyId}/add` : ''}`);
  }, [appId, menuId, menuItemDependencyId, navigate]);

  const onClickEdit = useCallback((selectedId) => {
    navigate(`/app/${appId}/menu/${menuId}/menu-item-dependency/${selectedId}`);
  }, [appId, menuId, navigate]);

  const onClickDelete = useCallback((selectedId) => {
    const selectedMenuItemDependency = menuItemDependencies.find(({ menu_item_dependency_id }) => menu_item_dependency_id === selectedId);
    if (!selectedMenuItemDependency?.menu_item?.menu_item_id) {
      return Promise.resolve();
    }
    return deleteMenuItem(selectedMenuItemDependency.menu_item.menu_item_id)
      .then(() => {
        return fetchMenuItemDependenciesFromApi();
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [fetchMenuItemDependenciesFromApi, menuItemDependencies, setAuth]);

  useEffect(() => {
    fetchMenuItemDependenciesFromApi();
  }, [fetchMenuItemDependenciesFromApi]);

  const menuItemTableColumns = useMemo(() => [
    { field: 'sequence' },
    {
      field: 'menu_item.label',
      label: 'Label',
    },
    {
      field: 'menu_item.type',
      label: 'Type',
      renderContent: ({ type }) => (
        <MKBadge
          color={type === 1 ? 'secondary' : 'info'}
          badgeContent={type === 1 ? 'Node' : 'Item'}
          container
        />
      ),
    },
    {
      field: 'menu_item.path',
      label: 'Path',
    },
    {
      field: 'menu_item.img_url',
      label: 'Image',
    },
    {
      field: 'menu_item.disabled',
      label: 'Disabled',
      formatter: (v) => (v ? 'Yes' : 'No'),
    },
  ], []);

  return (
    <MKBox mt={4}>
      <MKBox display="flex" justifyContent="space-between" alignItems="center" p={1}>
        <MKTypography variant="h6">Menu Items</MKTypography>
      </MKBox>
      <DataTable
        data={menuItemDependencies}
        columns={menuItemTableColumns}
        idField="menu_item_dependency_id"
        onPressAdd={onClickAdd}
        onPressEdit={onClickEdit}
        onPressDelete={onClickDelete}
        paginationDisabled
        selectable
      />
    </MKBox>
  );
};

MenuItemList.propTypes = {
  menuNodeId: PropTypes.string,
};

MenuItemList.defaultProps = {
  menuNodeId: null,
};

export default MenuItemList;
