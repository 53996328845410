import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import toLower from 'lodash/toLower';
import partition from 'lodash/partition';
import startCase from 'lodash/startCase';
import Grid from '@mui/material/Grid';
import TurnRightIcon from '@mui/icons-material/TurnRight';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import EditAttributeField from 'components/EditAttributeField';
import Button from 'components/Button';
import Select from 'components/Select';

const ATTRIBUTE_SUFFIX_IS_REDIRECT = '.is_redirect';
const ATTRIBUTE_SUFFIX_TARGET_COLLECTION_DEFINITION_ID = '.target_collection_definition_id';
const ATTRIBUTE_SUFFIX_TARGET_ATTRIBUTE_ID = '.target_attribute_id';

const EditAttributesForm = ({ attributes, config, collectionDefinitions, onSave, onCancel, ...props }) => {
  const initialValues = useMemo(() => {
    return (attributes || []).reduce((map, attr) => {
      const { attribute_id, default_value } = attr;
      const updatedMap = { ...map };
      updatedMap[attribute_id] = (config && attribute_id in config) ? config[attribute_id] : default_value;
      return updatedMap;
    }, {});
  }, [attributes, config]);

  const [mainAttributes, redirectAttributes] = useMemo(() => partition(attributes, (attribute) => {
    const attributeName = attribute?.name || '';
    return !(attributeName.endsWith(ATTRIBUTE_SUFFIX_IS_REDIRECT)
      || attributeName.endsWith(ATTRIBUTE_SUFFIX_TARGET_COLLECTION_DEFINITION_ID)
      || attributeName.endsWith(ATTRIBUTE_SUFFIX_TARGET_ATTRIBUTE_ID)
    );
  }), [attributes]);

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, setFieldValue, errors, values, isSubmitting, dirty, touched }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            {(mainAttributes || []).sort(
              (a1, a2) => a1.sequence - a2.sequence,
            ).map((attribute) => {
              const { attribute_id, name } = attribute;
              const isRedirectAttribute = redirectAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_IS_REDIRECT}`);
              const targetCollectionDefinitionIdAttribute = redirectAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_TARGET_COLLECTION_DEFINITION_ID}`);
              const targetAttributeIdAttribute = redirectAttributes.find((attr) => attr.name === `${name}${ATTRIBUTE_SUFFIX_TARGET_ATTRIBUTE_ID}`);

              const isRedirect = toLower(values[isRedirectAttribute?.attribute_id]) === 'true';
              const targetCollectionDefinition = collectionDefinitions.find(({ collection_definition_id }) => collection_definition_id === values[targetCollectionDefinitionIdAttribute?.attribute_id]);

              const targetCollectionDefinitionOptions = collectionDefinitions.map((collectionDefinition) => ({
                label: collectionDefinition.name,
                value: collectionDefinition.collection_definition_id,
              }));

              const targetCollectionDefinitionAttributeOptions = [
                {
                  label: 'ID',
                  value: 'id',
                },
                ...(targetCollectionDefinition?.attributes || []).map((targetAttribute) => ({
                  label: targetAttribute.name === '__system_order__' ? '# System Order' : targetAttribute.name,
                  value: targetAttribute.attribute_id,
                })),
                {
                  label: 'Created Date',
                  value: 'createddate',
                },
                {
                  label: 'Last Modified Date',
                  value: 'lastmoddate',
                },
              ];

              return (
                <MKBox key={attribute_id} mb={2}>
                  <MKBox display="flex" flexDirection="row">
                    <MKBox flex={2}>
                      <EditAttributeField
                        attribute={attribute}
                        value={!isRedirect ? values[attribute_id] : '-'}
                        setFieldValue={setFieldValue}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        disabled={isRedirect}
                      />
                    </MKBox>
                    {!!isRedirectAttribute && (
                      <MKBox ml={1} flex={1}>
                        <EditAttributeField
                          attribute={isRedirectAttribute}
                          value={values[isRedirectAttribute.attribute_id]}
                          label={startCase(isRedirectAttribute.name.replace(`${name}.`, ''))}
                          setFieldValue={setFieldValue}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                        />
                      </MKBox>
                    )}
                  </MKBox>
                  {isRedirect ? (
                    <MKBox mt={1} ml={2}>
                      {!!targetCollectionDefinitionIdAttribute && (
                        <MKBox display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <Select
                            name={targetCollectionDefinitionIdAttribute.attribute_id}
                            label={startCase(targetCollectionDefinitionIdAttribute.name.replace(`${name}.`, ''))}
                            value={values[targetCollectionDefinitionIdAttribute.attribute_id] || ''}
                            onChange={(v) => setFieldValue(targetCollectionDefinitionIdAttribute.attribute_id, v)}
                            options={targetCollectionDefinitionOptions}
                            {...props}
                          />
                        </MKBox>
                      )}
                      {!!targetAttributeIdAttribute && (
                        <MKBox mt={1} display="flex" flexDirection="row" alignItems="center">
                          <TurnRightIcon color="secondary" sx={{ transform: 'rotate(-90deg)', mr: 0.4 }} />
                          <Select
                            name={targetAttributeIdAttribute.attribute_id}
                            label={startCase(targetAttributeIdAttribute.name.replace(`${name}.`, ''))}
                            value={values[targetAttributeIdAttribute.attribute_id] || ''}
                            onChange={(v) => setFieldValue(targetAttributeIdAttribute.attribute_id, v)}
                            disabled={!targetCollectionDefinition?.attributes?.length}
                            options={targetCollectionDefinitionAttributeOptions}
                            {...props}
                          />
                        </MKBox>
                      )}
                    </MKBox>
                  ) : null}
                </MKBox>
              );
            })}
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <MKBox display="flex">
                  <MKBox display="flex" flex={1}>
                    <Button
                      onClick={onCancel}
                      variant="outlined"
                      color="secondary"
                      fullWidth
                    >
                      {dirty ? 'Cancel' : 'Back'}
                    </Button>
                  </MKBox>
                  <MKBox display="flex" flex={1} ml={2}>
                    <Button
                      type="submit"
                      variant="gradient"
                      color="info"
                      fullWidth
                      disabled={isSubmitting || !dirty}
                    >
                      Save
                    </Button>
                  </MKBox>
                </MKBox>
              </Grid>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditAttributesForm.propTypes = {
  attributes: PropTypes.arrayOf(
    PropTypes.shape({
      attribute_id: PropTypes.string,
      name: PropTypes.any,
      data_type: PropTypes.number,
      default_value: PropTypes.any,
    }),
  ),
  config: PropTypes.object,
  collectionDefinitions: PropTypes.array,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
};

EditAttributesForm.defaultProps = {
  attributes: [],
  config: {},
  collectionDefinitions: [],
  onSave: () => {},
  onCancel: () => {},
};

export default EditAttributesForm;
