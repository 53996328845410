import PropTypes from 'prop-types';
import Container from '@mui/material/Container';

const LayoutEditBarSection = ({ layout, ...props }) => {
  const { name } = layout || {};
  return (
    <Container sx={{ p: 2 }}>
      Layout:
      {' '}
      {name}
    </Container>
  );
};

LayoutEditBarSection.propTypes = {
  layout: PropTypes.shape({
    name: PropTypes.string,
  }),
};

LayoutEditBarSection.defaultProps = {
  layout: {},
};

export default LayoutEditBarSection;
