import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';

const Banner = ({
  src,
  title,
  titleProps,
  subtitle,
  subtitleProps,
  height,
  justifyContent,
  alignItems,
  backgroundSize,
  backgroundPosition,
  editable,
  onPressEdit,
  ...props
}) => {
  return (
    <MKBox
      display="flex"
      flexDirection="column"
      justifyContent={justifyContent}
      alignItems={alignItems}
      height={`${height}`}
      sx={{
        width: '100%',
        backgroundImage: `url(${src})`,
        backgroundSize: `${backgroundSize}`,
        backgroundPosition: { backgroundPosition },
      }}
      {...props}
    >
      <MKTypography
        sx={{ ...titleProps }}
      >
        {title}
      </MKTypography>
      <MKTypography
        sx={{ ...subtitleProps }}
      >
        {subtitle}
      </MKTypography>
    </MKBox>
  );
};

Banner.propTypes = {
  src: PropTypes.string,
  title: PropTypes.string,
  titleProps: PropTypes.object,
  subtitle: PropTypes.string,
  subtitleProps: PropTypes.object,
  height: PropTypes.string,
  justifyContent: PropTypes.string,
  alignItems: PropTypes.string,
  backgroundSize: PropTypes.string,
  backgroundPosition: PropTypes.string,
  editable: PropTypes.bool,
  onPressEdit: PropTypes.func,
};

Banner.defaultProps = {
  src: null,
  title: null,
  titleProps: {},
  subtitle: null,
  subtitleProps: {},
  justifyContent: 'center',
  alignItems: 'center',
  backgroundSize: '100% 100%',
  backgroundPosition: 'center',
};

export default Banner;
