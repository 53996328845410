import { useCallback } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Container from '@mui/material/Container';
import MKBox from 'components/MaterialKit/MKBox';
import { createEventTrigger } from 'api/event_triggers';
import EditEventTriggerForm from 'sections/EventTriggerEdit/EditEventTriggerForm';

const EventTriggerCreateSection = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const businessLogicId = searchParams.get('business_logic_id');

  const createEventTriggerToApi = useCallback((values, { setFieldError }) => {
    const payload = {
      event_trigger_name: values.event_trigger_name,
      event_trigger_type: values.event_trigger_type,
      output_signal_name: values.output_signal_name,
      business_logic: businessLogicId,
      event: values.event,
    };

    return createEventTrigger(payload)
      .then(() => {
        navigate(-1);
      })
      .catch((err) => {
        const res = err.response;
        setFieldError('form', res.data.error);
      });
  }, [businessLogicId, navigate]);

  return (
    <MKBox flex={1} py="2rem">
      <Container>
        <h4 style={{ marginBottom: '10px' }}>
          Create Event Trigger
        </h4>
        <EditEventTriggerForm
          onSave={createEventTriggerToApi}
        />
      </Container>
    </MKBox>
  );
};

export default EventTriggerCreateSection;
