import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import MKBox from 'components/MaterialKit/MKBox';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import InputField from 'components/InputField';

const getFormValidation = (values) => {
  const { temporary_password, new_password, reenter_new_password } = values;
  const errors = {};
  if (!temporary_password) {
    errors.temporary_password = 'Required';
  }
  if (!new_password) {
    errors.new_password = 'Required';
  }
  if (!reenter_new_password) {
    errors.reenter_new_password = 'Required';
  }
  if (new_password !== reenter_new_password) {
    errors.reenter_new_password = 'Entered password is not matched';
  }
  return errors;
};

const ResetPasswordForm = ({
  onResetPassword,
  initialValues,
  submitButtonColor,
  submitButtonTitle,
  submitTextColor,
  backButtonColor,
  backButtonTitle,
  backTextColor,
  emailPlaceholder,
  emailLabel,
  ...props }) => {
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onResetPassword}
      validate={getFormValidation}
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, touched, setFieldValue }) => {
        return (
          <MKBox
            component="form"
            role="form"
            onSubmit={handleSubmit}
            style={{
              padding: '0px',
              fontSize: '16px',
            }}
          >
            <Grid mb="30px">
              <MKTypography color="black" fontSize="18px">
                Forgot Password: Step 2
              </MKTypography>
            </Grid>
            <Grid mb="30px">
              <MKTypography color="black" fontSize="16px">
                Please enter the temporary password and your new password
              </MKTypography>
            </Grid>
            <Grid container mb="30px" justifyContent="center" alignItems="center">
              <Grid item xs={6} md={4} xl={4}>
                <MKTypography
                  variant="body1"
                  color="black"
                  fontSize="16px"
                >
                  Temporary password
                </MKTypography>
              </Grid>
              <Grid item xs={6} md={8} xl={8}>
                <InputField
                  name="temporary_password"
                  value={values.temporary_password || ''}
                  type="password"
                  label="Temporary password"
                  placeholder="Temporary password"
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={touched.temporary_password && !!errors.temporary_password}
                />
                {touched.temporary_password && !!errors.temporary_password && (
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {errors.temporary_password}
                  </MKTypography>
                )}
              </Grid>
            </Grid>
            <Grid container mb="30px" justifyContent="center" alignItems="center">
              <Grid item xs={6} md={4} xl={4}>
                <MKTypography
                  variant="body1"
                  color="black"
                  fontSize="16px"
                >
                  New password
                </MKTypography>
              </Grid>
              <Grid item xs={6} md={8} xl={8}>
                <InputField
                  name="new_password"
                  value={values.new_password || ''}
                  type="password"
                  label="New password"
                  placeholder="New password"
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={touched.new_password && !!errors.new_password}
                />
                {touched.new_password && !!errors.new_password && (
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {errors.new_password}
                  </MKTypography>
                )}
              </Grid>
            </Grid>
            <Grid container mb="30px" justifyContent="center" alignItems="center">
              <Grid item xs={6} md={4} xl={4}>
                <MKTypography
                  variant="body1"
                  color="black"
                  fontSize="16px"
                >
                  Re-enter new password
                </MKTypography>
              </Grid>
              <Grid item xs={6} md={8} xl={8}>
                <InputField
                  name="reenter_new_password"
                  value={values.reenter_new_password || ''}
                  type="password"
                  label="Re-enter new password"
                  placeholder="Re-enter new password"
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  handleBlur={handleBlur}
                  error={touched.reenter_new_password && !!errors.reenter_new_password}
                />
                {touched.reenter_new_password && !!errors.reenter_new_password && (
                  <MKTypography sx={{ ml: 1, mt: 0.5 }} variant="caption" color="error">
                    {errors.reenter_new_password}
                  </MKTypography>
                )}
              </Grid>
            </Grid>
            {!!errors.form && (
              <MKTypography variant="caption" color="error">{errors.form}</MKTypography>
            )}
            <Grid container mt="20vh" justifyContent="space-between" color="red">
              <Button color={backButtonColor} size="large" disabled={isSubmitting} style={{ border: '1px solid black', padding: '0px 50px' }}>
                <MKTypography color={backTextColor}>
                  {backButtonTitle}
                </MKTypography>
              </Button>
              <Button type="submit" color={submitButtonColor} size="large" disabled={isSubmitting} style={{ border: '1px solid black', padding: '0px 50px' }}>
                <MKTypography color={submitTextColor}>
                  {submitButtonTitle}
                </MKTypography>
              </Button>
            </Grid>
          </MKBox>
        );
      }}
    </Formik>
  );
};

ResetPasswordForm.propTypes = {
  onResetPassword: PropTypes.func,
  initialValues: PropTypes.object,
  submitButtonColor: PropTypes.string,
  submitButtonTitle: PropTypes.string,
  submitTextColor: PropTypes.string,
  backButtonColor: PropTypes.string,
  backButtonTitle: PropTypes.string,
  backTextColor: PropTypes.string,
  emailLabel: PropTypes.string,
  emailPlaceholder: PropTypes.string,
};

ResetPasswordForm.defaultProps = {
  onResetPassword: () => {},
  initialValues: {},
};

export default ResetPasswordForm;
