import { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import MKBox from 'components/MaterialKit/MKBox';
import MKInput from 'components/MaterialKit/MKInput';
import MKTypography from 'components/MaterialKit/MKTypography';
import Button from 'components/Button';
import { Checkbox, FormControl, MenuItem, Select, InputLabel } from '@mui/material';
import HandlerSignalListView from 'sections/HandlerSignalListView';
import HandlerParameterListView from 'sections/HandlerParameterListView';
import { getHandlerTypes, getHandlerSubTypes } from 'api/handler_types';
import { handleErrorResponse } from 'utils/general';
import { useAuth } from 'contexts/auth';
import { getFunctions } from 'api/functions';

const TASK_HANDLER_ID = 'e7f14ed6-496b-48dc-98d7-069413a4f31f';
const SEQUENCE_HANDLER_ID = '4fa25e71-4e3b-4d17-b4e6-aaabf8f886a8';
const DECISION_HANDLER_ID = '2ef85bd2-c0d4-43f2-959f-33de559315bd';

const EditHandlerForm = ({ handler, isEdit, onSave, ...props }) => {
  const initialValues = useMemo(() => ({
    handler_name: handler?.handler_name || '',
    handler_type: handler?.handler_type || '',
    handler_sub_type: handler?.handler_sub_type || '',
    sync: handler?.sync,
    delay_time: handler?.delay_time,
    handler_signals: handler?.handler_signals?.map((handlerSignal) => handlerSignal.handler_signal_id) || [],
    selected_signals: handler?.handler_signals?.map((handlerSignal) => handlerSignal.signal) || [],
    function: handler?.function || '',
    data: handler?.data || '',
    expression: handler?.expression || '',
  }), [handler]);

  const [sync, setSync] = useState(true);
  const [handlerTypes, setHandlerTypes] = useState([]);
  const [handlerSubTypes, setHandlerSubTypes] = useState([]);
  const [handlerType, setHandlerType] = useState('');
  const [handlerSubType, setHandlerSubType] = useState('');
  const [func, setFunction] = useState('');
  const [functions, setFunctions] = useState([]);
  const { setAuth } = useAuth();
  const [selectedIngoingSignals, setSelectedIngoingSignals] = useState([]);

  const fetchHandlerTypesFromApi = useCallback(() => {
    const params = {
      $orderby: 'handler_type_name',
    };
    return getHandlerTypes(params)
      .then(({ data }) => {
        setHandlerTypes(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const fetchHandlerSubTypesFromApi = useCallback(() => {
    const params = {
      $orderby: 'handler_sub_type_name',
    };
    return getHandlerSubTypes(params)
      .then(({ data }) => {
        setHandlerSubTypes(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  const fetchFunctionsFromApi = useCallback(() => {
    const params = {
      $orderby: 'function_name',
    };
    return getFunctions(params)
      .then(({ data }) => {
        setFunctions(data || []);
      })
      .catch((err) => {
        handleErrorResponse(err, setAuth);
      });
  }, [setAuth]);

  useEffect(() => {
    fetchHandlerTypesFromApi();
    fetchHandlerSubTypesFromApi();
    fetchFunctionsFromApi();
  }, [fetchHandlerTypesFromApi, fetchHandlerSubTypesFromApi, fetchFunctionsFromApi]);

  useEffect(() => {
    setHandlerType(handler?.handler_type);
    setHandlerSubType(handler?.handler_sub_type);
    setFunction(handler?.function);
    setSync(handler?.sync);
    setSelectedIngoingSignals(handler?.handler_signals?.map((handlerSignal) => handlerSignal.signal) || []);
  }, [handler]);

  const handleCheckbox = (event, setFieldValue) => {
    let selectedSignals = selectedIngoingSignals || [];
    if (event.target.checked) {
      if (selectedSignals.length === 0 || !selectedIngoingSignals.includes(event.target.value)) {
        selectedSignals = [...selectedIngoingSignals, event.target.value];
        setSelectedIngoingSignals(selectedSignals);
      }
    } else {
      selectedSignals = selectedIngoingSignals.filter((signal) => signal !== event.target.value);
      setSelectedIngoingSignals(selectedSignals);
    }
    setFieldValue('selected_signals', selectedSignals);
  };

  return (
    <Formik
      onSubmit={onSave}
      initialValues={initialValues}
      enableReinitialize
      {...props}
    >
      {({ handleChange, handleBlur, handleSubmit, errors, values, isSubmitting, dirty, setFieldValue }) => {
        return (
          <MKBox component="form" role="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid container item xs={12}>
                <MKInput
                  name="handler_name"
                  label="Handler Name"
                  value={values.handler_name || ''}
                  onChange={handleChange('handler_name')}
                  onBlur={handleBlur('handler_name')}
                  fullWidth
                />
              </Grid>

              <Grid container item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="handler_type_label">Handler Type</InputLabel>
                  <Select
                    labelId="handler_type_label"
                    id="handler_type_select"
                    value={handlerType}
                    label="Handler Type"
                    onChange={(e) => {
                      setFieldValue('handler_type', e.target.value);
                      setHandlerType(e.target.value);
                    }}
                    onBlur={handleBlur('handler_type')}
                  >
                    <MenuItem disabled value="">
                      Handler Type
                    </MenuItem>
                    {handlerTypes.map((type) => <MenuItem key={type.handler_type_id} value={type.handler_type_id}>{type.handler_type_name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>

              {values.handler_type === SEQUENCE_HANDLER_ID && (
                <Grid container item xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="handler_sub_type_label">Handler Sub Type</InputLabel>
                    <Select
                      labelId="handler_sub_type_label"
                      id="handler_sub_type_select"
                      value={handlerSubType}
                      label="Handler Sub Type"
                      onChange={(e) => {
                        setFieldValue('handler_sub_type', e.target.value);
                        setHandlerSubType(e.target.value);
                      }}
                      onBlur={handleBlur('handler_sub_type')}
                    >
                      <MenuItem disabled value="">
                        Handler Sub Type
                      </MenuItem>
                      {handlerSubTypes.map((subType) => <MenuItem key={subType.handler_sub_type_id} value={subType.handler_sub_type_id}>{subType.handler_sub_type_name}</MenuItem>)}
                    </Select>
                  </FormControl>
                </Grid>
              )}

              {values.handler_type === TASK_HANDLER_ID && (
                <Grid container item xs={12}>
                  <span style={{ display: 'flex', alignItems: 'center', fontSize: '1rem' }}>Sync</span>
                  <Checkbox
                    checked={sync}
                    onClick={() => {
                      setFieldValue('sync', !values.sync);
                      setSync(!sync);
                    }}
                    onBlur={handleBlur('sync')}
                    color="secondary"
                  />
                </Grid>
              )}

              {!values.sync && values.handler_type === TASK_HANDLER_ID && (
                <Grid container item xs={12}>
                  <MKInput
                    name="delay_time"
                    label="Delay Time (ms)"
                    value={values.delay_time || null}
                    onChange={handleChange('delay_time')}
                    onBlur={handleBlur('delay_time')}
                    fullWidth
                  />
                </Grid>
              )}

              {values.handler_type === TASK_HANDLER_ID && (
              <Grid container item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id="function_label">Function</InputLabel>
                  <Select
                    labelId="function_label"
                    id="function_select"
                    value={func}
                    label="Function"
                    onChange={(e) => {
                      setFieldValue('function', e.target.value);
                      setFunction(e.target.value);
                    }}
                    onBlur={handleBlur('function')}
                  >
                    <MenuItem disabled value="">
                      Function
                    </MenuItem>
                    {functions.map((funct) => <MenuItem key={funct.function_id} value={funct.function_id}>{funct.function_name}</MenuItem>)}
                  </Select>
                </FormControl>
              </Grid>
              )}

              {values.handler_type === DECISION_HANDLER_ID && (
                <>
                  <Grid item xs={12}>
                    <MKInput
                      name="data"
                      label="Data"
                      value={values.data || ''}
                      onChange={handleChange('data')}
                      onBlur={handleBlur('data')}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <MKInput
                      name="expression"
                      label="Expression"
                      value={values.expression || ''}
                      onChange={handleChange('expression')}
                      onBlur={handleBlur('expression')}
                      fullWidth
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12}>
                <HandlerSignalListView signalType="in" selectedSignals={selectedIngoingSignals} handleCheckbox={handleCheckbox} setFieldValue={setFieldValue} />
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end">
              <Grid item xs={12} md={6} xl={4}>
                <MKTypography variant="caption" color="error">
                  {errors.form}
                  &nbsp;
                </MKTypography>
                <Button
                  type="submit"
                  variant="gradient"
                  color="info"
                  disabled={isSubmitting || !dirty}
                  fullWidth
                >
                  {isSubmitting ? (
                    <CircularProgress color="white" size={24} sx={{ position: 'absolute' }} />
                  ) : 'Save'}
                </Button>
              </Grid>
            </Grid>
            {isEdit && (
            <Grid>
              <HandlerSignalListView signalType="out" isDecisionHandler={values.handler_type === DECISION_HANDLER_ID} />
              <HandlerParameterListView />
            </Grid>
            )}
          </MKBox>
        );
      }}
    </Formik>
  );
};

EditHandlerForm.propTypes = {
  handler: PropTypes.shape({
    handler_name: PropTypes.string,
    handler_type: PropTypes.string,
    handler_sub_type: PropTypes.string,
    sync: PropTypes.bool,
    delay_time: PropTypes.number,
    handler_signals: PropTypes.array,
    function: PropTypes.string,
    data: PropTypes.string,
    expression: PropTypes.string,
  }),
  isEdit: PropTypes.bool,
  onSave: PropTypes.func,
};

EditHandlerForm.defaultProps = {
  handler: {},
  isEdit: false,
  onSave: () => {},
};

export default EditHandlerForm;
