import PropTypes from 'prop-types';
import MKBox from 'components/MaterialKit/MKBox';
import Button from 'components/Button';

const TablePageActionButtonGroup = ({ page, count, rowsPerPage, onPageChange, buttonProps, ...props }) => {
  const totalPage = Math.max(Math.ceil(count / parseInt(rowsPerPage, 10)), 1);
  return (
    <MKBox display="flex" justifyContent="center" alignItems="center" width="100%" p={1}>
      <Button
        size="small"
        disabled={page === 0}
        onClick={(e) => onPageChange(e, 0)}
        {...buttonProps}
      >
        First
      </Button>
      <MKBox pl={1}>
        <Button
          size="small"
          disabled={page <= 0}
          onClick={(e) => onPageChange(e, page - 1)}
          {...buttonProps}
        >
          Previous
        </Button>
      </MKBox>
      <MKBox
        display="flex"
        justifyContent="center"
        px={2}
      >
        {`Page ${page + 1} of ${totalPage}`}
      </MKBox>
      <MKBox>
        <Button
          size="small"
          disabled={page >= totalPage - 1}
          onClick={(e) => onPageChange(e, page + 1)}
          {...buttonProps}
        >
          Next
        </Button>
      </MKBox>
      <MKBox pl={1}>
        <Button
          size="small"
          disabled={page >= totalPage - 1}
          onClick={(e) => onPageChange(e, Math.max(0, totalPage - 1))}
          {...buttonProps}
        >
          Last
        </Button>
      </MKBox>
    </MKBox>
  );
};

TablePageActionButtonGroup.propTypes = {
  page: PropTypes.number,
  count: PropTypes.number,
  rowsPerPage: PropTypes.number,
  onPageChange: PropTypes.func,
  buttonProps: PropTypes.object,
};

TablePageActionButtonGroup.defaultProps = {
  page: 0,
  count: 0,
  rowsPerPage: 10,
  onPageChange: () => {},
  buttonProps: {},
};

export default TablePageActionButtonGroup;
