import React from 'react';
import PropTypes from 'prop-types';
import Select from 'components/Select';
import TextInput from 'components/TextInput';
import RichTextEditor from 'components/RichTextEditor';
import MKDatePicker from 'components/MaterialKit/MKDatePicker';
import FileUploaderSection from 'sections/FileUploader';
import { datetimeFormatter, DATE_FORMAT_DATE_TIME_DEFAULT } from 'utils/datetime';

export const INPUT_TYPE_TEXT = 'text';
export const INPUT_TYPE_TEXTAREA = 'textarea';
export const INPUT_TYPE_RICH_TEXT = 'rich_text';
export const INPUT_TYPE_NUMBER = 'number';
export const INPUT_TYPE_SELECT = 'select';
export const INPUT_TYPE_BOOLEAN = 'boolean';
export const INPUT_TYPE_FILE = 'file';
export const INPUT_TYPE_IMAGE = 'image';
export const INPUT_TYPE_VIDEO = 'video';
export const INPUT_TYPE_AUDIO = 'audio';
export const INPUT_TYPE_DATETIME = 'datetime';

export const booleanOptions = [
  { label: 'True', value: 'true' },
  { label: 'False', value: 'false' },
];

const getAcceptFileTypes = (inputType) => {
  switch (inputType) {
    case INPUT_TYPE_IMAGE:
      return ['image/*'];
    case INPUT_TYPE_VIDEO:
      return ['video/*'];
    case INPUT_TYPE_AUDIO:
      return ['audio/*'];
    default:
      return ['*/*'];
  }
};

const InputField = ({ name, value, label, type, options, handleChange, setFieldValue, handleBlur, disabled, ...props }) => {
  switch (type) {
    case INPUT_TYPE_BOOLEAN:
    case INPUT_TYPE_SELECT:
      return (
        <Select
          name={name}
          label={label}
          value={value}
          onChange={(v) => setFieldValue(name, v)}
          options={type === INPUT_TYPE_BOOLEAN ? booleanOptions : options}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_DATETIME:
      return (
        <MKDatePicker
          name={name}
          value={value || null}
          onChange={([v]) => setFieldValue(name, v.valueOf())}
          input={{
            name,
            label,
            fullWidth: true,
            value: value ? datetimeFormatter(value, DATE_FORMAT_DATE_TIME_DEFAULT) : '',
            disabled,
          }}
          options={{
            enableTime: true,
            dateFormat: DATE_FORMAT_DATE_TIME_DEFAULT,
            formatDate: (date, format, locale) => {
              return datetimeFormatter(date, format);
            },
          }}
          {...props}
        />
      );
    case INPUT_TYPE_IMAGE:
    case INPUT_TYPE_VIDEO:
    case INPUT_TYPE_AUDIO:
    case INPUT_TYPE_FILE:
      return (
        <FileUploaderSection
          file_id={value || ''}
          onChange={(v) => setFieldValue(name, v)}
          input_label={label}
          accept_file_types={getAcceptFileTypes(type)}
          image_croppable={[INPUT_TYPE_IMAGE].includes(type)}
          disabled={disabled}
          {...props}
        />
      );
    case INPUT_TYPE_RICH_TEXT:
      return (
        <RichTextEditor
          label={label}
          value={value || ''}
          onChange={handleChange(name)}
          readOnly={disabled}
          {...props}
        />
      );
    default:
      return (
        <TextInput
          name={name}
          label={label}
          value={value}
          onChange={handleChange(name)}
          onBlur={handleBlur(name)}
          type={type}
          disabled={disabled}
          inputProps={{
            step: 'any',
          }}
          {...(type === INPUT_TYPE_TEXTAREA ? {
            multiline: true,
            minRows: 3,
            maxRows: 12,
          } : {})}
          {...props}
        />
      );
  }
};

InputField.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  label: PropTypes.string,
  type: PropTypes.string,
  options: PropTypes.array,
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func,
  handleBlur: PropTypes.func,
  disabled: PropTypes.bool,
};

InputField.defaultProps = {
  name: '',
  value: null,
  label: 'Label',
  type: INPUT_TYPE_TEXT,
  options: [],
  handleChange: () => {},
  setFieldValue: () => {},
  handleBlur: () => {},
  disabled: false,
};

export default InputField;
